<template>
    <div class="service_details_container" style="overflow-x: hidden;">
        <div class="form-preloader" v-if="preparingForm">
            <div class="preloader-spinner">
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="preloader-text">Preparing form. Please wait...</div>
        </div>
        <img class="title_image" src="../../assets/img_shipping.png" />
        <h4>START SHIPPING - QUOTE AND BOOK</h4>
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
            <!-- <section>
        <h2>CHOOSE ENTRY METHOD</h2>
        <div class="radio_wrapper1">
          <label>
            <input type="radio" name="entrychoose" value="single" id="single" v-model="draft.entry_type" />
            <span>Single</span>
          </label>
          <label>
            <input type="radio" name="entrychoose" value="batch" id="batch" v-model="draft.entry_type"  :disabled="auth().user_type == 'user' ">
            <span>Batch</span>
          </label>
        </div>
      </section> -->
            <section>
                <div class="h2_wiith_icons">
                    <div class="help_links">
                        <router-link to="/pages/help"
                            ><span>Help</span></router-link
                        >
                        |
                        <router-link to="/pages/faq"
                            ><span>FAQ</span></router-link
                        >
                    </div>
                    <div class="adj_cover">
                        <h2>QUOTE</h2>
                        <div class="adj_in">
                            <i class="material-icons">info</i>

                            <div class="info_box">
                                <header>Quote</header>
                                <section>
                                    Select carriers based on type of shipment
                                    you are sending, pick up, delivery address
                                    and weight.
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider
                                    name="requester name"
                                    rules="required|max:100"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Requester Name
                                            <span>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Name Character Limit 100"
                                            v-model="draft.requester_name"
                                            :maxlength="max4"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                            :disabled="isDisabled"
                                        />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider
                                    name="unit name"
                                    rules="required"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Units / Branch
                                            <span>*</span>
                                        </label>
                                        <select
                                            v-model="draft.branch_id"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                            :disabled="isDisabled"
                                        >
                                            <option value=""
                                                >Select a branch</option
                                            >
                                            <option
                                                v-for="(branch, i) in branches"
                                                :key="'branches' + i"
                                                :value="branch.id"
                                                >{{ branch.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Request Date
                                        <span>*</span>
                                    </label>
                                    <flat-pickr
                                        class="date_for_pos_fixed"
                                        v-model="draft.request_date"
                                        :config="configDate"
                                        placeholder="Date"
                                        name="birthdate"
                                        :disabled="enable_edit_var == false"
                                    ></flat-pickr>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="form_section1" v-if="edit_mode == true">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <a
                                    class="new_yellow_edit_bt"
                                    @click="enableEditMode"
                                    >EDIT BOOKING DETAILS</a
                                >
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>

                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Pickup Address
                                        <span>*</span>
                                    </label>
                                    <label>
                                        <span>
                                            <a
                                                @click="changePickup = true"
                                                v-if="enable_edit_var != false"
                                                style="background-color: #004a7c;padding: 0.7rem;padding: 6px 7px;color:#fff;"
                                            >
                                                PICKUP ADDRESS
                                            </a>
                                        </span>
                                        <span style="padding-left:5px;">
                                            <a
                                                @click="
                                                    viewCompanyAddressBook('pickup')
                                                "
                                                v-if="enable_edit_var != false"
                                                style="background-color: #004a7c;padding: 0.7rem;padding: 6px 7px;color:#fff;"
                                            >
                                                COMPANY ADDRESS BOOK
                                            </a>
                                        </span>
                                        <span style="padding-left:5px;">
                                            <a
                                                @click="
                                                    viewPersonalAddressBook(
                                                        'pickup'
                                                    )
                                                "
                                                v-if="enable_edit_var != false"
                                                style="background-color: #004a7c;padding: 0.7rem;padding: 6px 7px;color:#fff;"
                                            >
                                                PERSONAL ADDRESS BOOK
                                            </a>
                                        </span>
                                        <span style="padding-left:5px;cursor:pointer;" v-if="rateCalculator(pickup_rate)">
                                            <span @click="viewAddressHistory(pickup_rate)" style="color: #004a7c;font-weight: bold;font-size: 1rem;">
                                                {{ rateCalculator(pickup_rate)}} %
                                            </span>
                                        </span>
                                    </label>
                                </div>

                                <div class="pickup-address-label">
                                    <span v-if="draft.pickup_floor">
                                        Floor# {{ draft.pickup_floor }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_desk">
                                        Desk# {{ draft.pickup_desk }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_address_1">
                                        {{ draft.pickup_address_1 }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_address_2">
                                        {{ draft.pickup_address_2 }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_suburb">
                                        {{ draft.pickup_suburb }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_city">
                                        {{ draft.pickup_city }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_state">
                                        {{ draft.pickup_state }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_postal_code">
                                        {{ draft.pickup_postal_code }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_country">
                                        {{ draft.pickup_country }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider
                                    name="type of good"
                                    rules="required"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Type of Goods
                                            <span>*</span>
                                        </label>
                                        <select
                                            v-model="draft.type_of_good"
                                            @change="fetchDispatch()"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                            :disabled="enable_edit_var == false"
                                        >
                                            <option value="" disabled
                                                >Select package type</option
                                            >
                                            <option
                                                v-for="(good, i) in goods"
                                                :key="'goods' + i"
                                                :value="good.id"
                                                >{{ good.type }}</option
                                            >
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider
                                    name="mode of dispatch"
                                    rules="required"
                                    v-slot="{ errors }"
                                    slim
                                >
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Service Type
                                            <span>*</span>
                                        </label>
                                        <select
                                            v-model="draft.carrier_service_type"
                                            :class="
                                                errors[0]
                                                    ? 'warning-border'
                                                    : ''
                                            "
                                            :disabled="(enable_edit_var == false && auth().user_type == 'user' && draft.is_default_courier_messenger) || (enable_edit_var && auth().user_type == 'user' && draft.is_default_courier_messenger)"
                                            @change="onServiceTypeChange($event)"
                                        >
                                            <option value="" disabled
                                                >Select Service Type</option
                                            >
                                            <option
                                                v-for="(type,
                                                i) in servicetypes"
                                                :key="'types' + i"
                                                :value="type.id"
                                                >{{ type.service_type }}</option
                                            >
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <template v-if="'Postal' == draft.delivery_service_type">
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Recipient Name
                                        <span>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        :placeholder="`Enter Character Limit ${max6}`"
                                        :maxlength="max6"
                                        v-model="draft.recipient_name"
                                    />
                                </div>
                            </td>
                            </template>
                            <template v-else>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Item Weight
                                        <span>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        v-model="draft.weight"
                                        :disabled="enable_edit_var == false"
                                        @change="updateWeight()"
                                    />
                                </div>
                            </td>
                            </template>
                        </tr>
                        <tr class="equal_3">
							<td>
								<div class="label_and_element_wrapper">
									<label>
										Select from Address Books
									</label>
                                    <span style="margin-left: 3px;">
                                        <a
											@click="
												viewCompanyAddressBook(
													'delivery'
												)
											"
											v-if="enable_edit_var != false"
                                            class="delivery-address-label"
                                        >
                                            COMPANY ADDRESS BOOK
                                        </a>
                                    </span>
									<span style="margin-left: 3px;">
                                        <a
											@click="
												viewPersonalAddressBook(
													'delivery'
												)
											"
											v-if="enable_edit_var != false"
											class="delivery-address-label"
                                        >
                                            PERSONAL ADDRESS BOOK
                                        </a>
                                    </span>
                                    <span style="padding-left:5px;cursor:pointer;" v-if="rateCalculator(deliver_rate)">
                                        <span @click="viewAddressHistory(deliver_rate)" style="color: #004a7c;font-weight: bold;font-size: 1rem;">
                                            {{ rateCalculator(deliver_rate)}} %
                                        </span>
                                    </span>
								</div>
							</td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Street
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Street Address"
                                        v-model="draft.delivery_address_1"
                                        ref="delivery_address_1"
                                        @focus="
                                            fetchLocationApi(
                                                'delivery_address_1'
                                            )
                                        "
                                        autocomplete="new-password"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td>
							<!-- <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Floor Number
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 60"
                                        v-model="draft.delivery_floor"
                                        autocomplete="new-password"
                                        @change="getRate('delivery')"
                                        :maxlength="max"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td> -->
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Suburb
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 60"
                                        v-model="draft.delivery_suburb"
                                        autocomplete="new-password"
                                        @change="getRate('delivery')"
                                        :maxlength="max"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr class="equal_3">
							<!-- <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Desk Number
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 60"
                                        v-model="draft.delivery_desk"
                                        autocomplete="new-password"
                                        @change="getRate('delivery')"
                                        :maxlength="max"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td> -->
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery City
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 60"
                                        v-model="draft.delivery_city"
                                        autocomplete="new-password"
                                        @change="getRate('delivery')"
                                        :maxlength="max"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery State
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 60"
                                        v-model="draft.delivery_state"
                                        autocomplete="new-password"
                                        @change="getRate('delivery')"
                                        :maxlength="max"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td>
							<td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Postcode
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 60"
                                        v-model="draft.delivery_postal_code"
                                        autocomplete="new-password"
                                        @change="getRate('delivery')"
                                        :maxlength="max"
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
                            </td>
                        </tr>
						<tr>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Country
                                    </label>
                                    <select-dropdown
                                        v-if="lcountries && lcountries.length > 0"
                                        :id="draft.delivery_country_code"
                                        :items="lcountries"
                                        @setSelected="setSelectedCountry">
                                    </select-dropdown>
                                </div>
                            </td>
                            <template v-if="'Non-contract' != draft.priority">
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Matter Number
                                        </label>
                                        <!-- <v-select 
                                            v-model="draft.matter_number_id" 
                                            label="matter_number"
                                            :options="matter_numbers">
                                        </v-select> -->
                                        <select-dropdown 
                                            :id="draft.matter_number_id"
                                            :items="matter_numbers"
                                            @setSelected="setSelectedMatterNumber">
                                        </select-dropdown>
                                    </div>
                                </td>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            {{gl_code_placeholder}}
                                        </label>
                                        <input
                                            type="text"
                                            :placeholder="gl_code_placeholder"
                                            v-model="draft.gl_code"
                                            :maxlength="max"
                                            :disabled="disable_gl_code"
                                            :class="{ 'w40': hub_admin}"
                                        />
                                        <div v-if="hub_admin">
                                            <label style="width: 20%; position:absolute; left: 240px; bottom: 0px">
                                                PAL Code
                                                <input
                                                    name="custom_code"
                                                    type="radio"
                                                    @click="selectCustomCode('PAL Code')"
                                                    id="radio_pal"
                                                />
                                            </label>
                                            <label style="width: 35%; position:absolute; left: 330px; bottom: 0px">
                                                Other Management Code
                                                <input
                                                    name="custom_code"
                                                    type="radio"
                                                    @click="selectCustomCode('Other Management Code')"
                                                    id="radio_other"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </template>
						</tr>
						<tr>
							<td style="display:flex;" v-if="'Postal' == draft.delivery_service_type">
                                <div class="user_type" style="width:50%;text-align: center;margin-top: 6%;">
                                    <label class="chk_label">
                                        <span style="display: table-caption;">
                                            Contract
                                        </span>
                                        <input
                                            name="usertype"
                                            type="radio"
                                            class="chk_standard"
                                            value="Contract"
                                            v-model="draft.priority"
                                        />
                                    </label>
                                    &nbsp;&nbsp;
                                    <label class="chk_label">
                                        <span style="display: table-caption;">
                                            Non-contract
                                        </span>
                                        <input
                                            name="usertype"
                                            type="radio"
                                            class="chk_standard"
                                            value="Non-contract"
                                            v-model="draft.priority"
                                        />
                                    </label>
                                </div>
                                <div style="width:50%;" class="label_and_element_wrapper">
                                    <label>
                                        Reference Number
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter Character Limit 120"
                                        v-model="draft.reference_number"
                                        :maxlength="max5"
                                        v-bind:class="
                                            error_class == true
                                                ? 'new-error'
                                                : ''
                                        "
                                        :disabled="enable_edit_var == false"
                                    />
                                </div>
							</td>
							<td v-else>

                                <div class="user_type">
                                    <label class="chk_label">
                                        <span style="display: table-caption;">
                                            Standard
                                        </span>
                                        <input
                                            name="usertype"
                                            type="radio"
                                            class="chk_standard"
                                            value="Standard"
                                            v-model="draft.priority"
                                        />
                                    </label>
                                    &nbsp;&nbsp;
                                    <label class="chk_label">
                                        <span style="display: table-caption;">
                                            Express
                                        </span>
                                        <input
                                            name="usertype"
                                            type="radio"
                                            class="chk_standard"
                                            value="Express"
                                            v-model="draft.priority"
                                        />
                                    </label>
                                    &nbsp;&nbsp;
                                    <label class="chk_label">
                                        <span style="display: table-caption;">
                                            Immediate
                                        </span>
                                        <input
                                            name="usertype"
                                            type="radio"
                                            class="chk_standard"
                                            value="Immediate"
                                            v-model="draft.priority"
                                        />
                                    </label>
                                </div>
                            </td>
						</tr>
                        <tr>
							<!-- <td></td> -->
                            <td v-if="!findcourier_btn.disabled && !findcourier_btn.length"></td>
                            <td v-if="!findcourier_btn.disabled && findcourier_btn.length" style="position: relative;">
                                <div
                                    data-v-531a3342=""
                                    class="find-couriers-preloader"
                                >
                                    <div
                                        data-v-531a3342=""
                                        style="float: right;padding-top: 3px;margin-left: 6px;"
                                    >
                                        No Couriers Found...
                                    </div>
                                </div>
                            </td>
                            <td
                                style="position: relative;"
                                v-if="findcourier_btn.disabled"
                            >
                                <div
                                    data-v-531a3342=""
                                    class="find-couriers-preloader"
                                >
                                    <img
                                        data-v-531a3342=""
                                        src="../../assets/loader.svg"
                                        style="height: 25px; float: left;"
                                    />
                                    <div
                                        data-v-531a3342=""
                                        style="float: right;padding-top: 3px;margin-left: 6px;"
                                    >
                                        Finding the Best Couriers for you. Please Wait...
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <template v-if="'Postal' != draft.delivery_service_type">
                                <a 
                                    class="new_yellow_bt"
                                    @click="findCouriers"
                                    :disabled="findcourier_btn.disabled"
                                    v-if="enable_edit_var != false"
                                    >{{ findcourier_btn.value }}</a
                                >
                                </template>
                                <a v-else href="javascript:void(0)" class="new_yellow_bt" @click="savePostalQuote">
                                    {{ save_postal_btn.value }}
                                </a>
                                <a  v-if="'Non-contract' != draft.priority" class="new_yellow_bt" @click="changeGlCode = true" style="background: #006A4E;">{{ hub_admin ? 'Select' : 'Change' }} GL</a>
                                <!-- <a class="new_yellow_bt" @click="findCouriers" >FIND COURIERS</a> -->
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- <div class="form_section1" v-if="draft.mode_of_dispatch">
                    <label class="radio_with_img_labels" v-for="(provider,i) in providers" :key="'provider'+i">
                        <a class="view_rate_card" v-if="provider.rate_card" :href="provider.rate_card" target="_blank">
                            <img :src="provider.logo" :alt="provider.name" />
                        </a>
                        <div v-if="provider.rate_card != 'null' ">
                            <a class="view_rate_card" :href="provider.rate_card" target="_blank">
                                <img :src="provider.logo" :alt="provider.name" />
                            </a>
                        </div>
                        <div v-else>
                            <img :src="provider.logo" :alt="provider.name" />
                        </div>
                        <input type="radio" :checked="provider.id == draft.provider_id" name="provider" :ref="provider.id" @click="selectRateCard(provider)" />
                    </label>
                </div> -->
            </section>

            <section v-if="courier_list == true || edit_mode == true">
                <div class="h2_wiith_icons">
                <!-- <div class="help_links">
                    <router-link to="/pages/help"><span>Help</span></router-link> | <router-link to="/pages/faq"><span>FAQ</span></router-link>
                </div> -->
                    <div class="adj_cover">
                        <h2>COURIER OPTIONS</h2>
                        <div class="adj_in">
                            <i class="material-icons">info</i>
                            <div class="info_box">
                                <header>Courier Options</header>
                                <section>
                                    Compare carrier results and select the
                                    appropriate carrier
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_section1">
                    <div class="special_panel_2">
                        <header>
                            <div class="title_cover">Delivery Partner</div>
                            <div class="columns_cover">
                                <div>Service Name</div>
                                <div>Transit Time</div>
                                <!-- <div>Rating</div> -->
                                <div>DG</div>
                                <div>Special Notes</div>
                                <div>Courier Cost</div>
                            </div>
                        </header>
                        <div
                            class="courier_row "
                            v-bind:class="
                                ratecourier.is_carrier_preference == 1
                                    ? ''
                                    : ''
                            "
                            v-for="(ratecourier, i) in ratecouriers"
                            :key="'ratecourier' + i"
                        >
                            <div class="main_cover">
                                <label class="logo_selected">
                                    <img
                                        :src="ratecourier.logo"
                                        :alt="ratecourier.name"
                                    />
                                    <!-- <img v-if="ratecourier.is_carrier_preference == 1" src="../../assets/recommended.jpg" /> -->
                                    <div class="radio_adj">
                                        <input
                                            type="radio"
                                            name="selected_courier"
                                            @click="SelectCourier(ratecourier)"
                                            :checked="ratecourier.is_checked"
                                        />
                                        <!-- <input type="radio"  name="selected_courier" :value="ratecourier.is_checked" v-model="ratecourier.is_checked"   @click="SelectCourier(ratecourier)" > -->
                                    </div>
                                </label>
                                <div class="content_selected">
                                    {{ ratecourier.description }}
                                </div>
                            </div>
                            <div class="columns_cover">
                                <div>{{ ratecourier.service_name }}</div>
                                <div>
                                    {{ ratecourier.transit_time }}&nbsp;days
                                </div>
                                <!-- <div>
                                    <span
                                        class="stars"
                                        v-for="i in ratecourier.sell_rate_margin *
                                            1"
                                        :key="i"
                                    >
                                        *
                                    </span>
                                </div> -->
                                <div>
                                    {{ ratecourier.is_dg == 1 ? "Yes" : "No" }}
                                </div>
                                <div>
                                    <a
                                        class="view_doc"
                                        :href="ratecourier.sla"
                                        target="_blank"
                                        v-if="ratecourier.sla.includes('SLA')"
                                    >
                                        Notes
                                    </a>
                                    <span
                                        class="view_doc"
                                        v-if="!ratecourier.sla.includes('SLA')"
                                    >
                                        No Files
                                    </span>
                                </div>
                                <div class="sub_info">
                                    $ {{ ratecourier.cost }}
                                    <!-- <div class="adj_in">
                                        <i class="material-icons">info</i>
                                        <div class="info_box">
                                            <header>Courier Options</header>
                                            <section>{{ratecourier.description}}</section> 
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- DISBLED FROM KWM ONLY
                <div class="form_section1 nobg" v-if="auth().user_type != 'user'">
                    <div class="info_section">
                        <h3>
                            <span class="material-icons"> report_problem</span>
                            IMPORTANT
                        </h3>
                        <p>
                            Always call the courier prior to pick up time to
                            arrange pickup.
                        </p>
                    </div>

                    <div class="info_section">
                        <h3>
                            <span class="material-icons"> report_problem</span>
                            IMPORTANT
                        </h3>
                        <p>
                            Always choose the recommended courier if you are not
                            sure about the best courier.
                        </p>
                    </div>
                    <div class="info_section">
                        <h3>
                            <span class="material-icons"> report_problem</span>
                            IMPORTANT
                        </h3>
                        <p>
                            If you are not able to find any suitable options to
                            send your item,
                            <strong
                                >contact :<a>support@dbsa.com</a> or
                                <a>mail room provider.</a></strong
                            >
                        </p>
                    </div>
                    <div class="info_section">
                        <h3>
                            <span class="material-icons"> report_problem</span>
                            IMPORTANT
                        </h3>
                        <p>
                            Always be mindful about the monthly budget while
                            choosing a courier.
                        </p>
                    </div>
                </div>
                -->
            </section>
                <div  v-if="'Postal' != draft.delivery_service_type" class="h2_wiith_icons">
                    <!-- <div class="help_links">
                        <router-link to="/pages/help"><span>Help</span></router-link> | <router-link to="/pages/faq"><span>FAQ</span></router-link>
                    </div> -->
                    <div class="adj_cover">
                        <h2>ADDRESS & SHIPMENT DETAILS</h2>
                        <div class="adj_in">
                            <i class="material-icons">info</i>

                            <div class="info_box">
                                <header>Address & Shipment Details</header>
                                <section>
                                    Book shipment and generate the label for
                                    shipment pickup
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="new_flexed_class"  v-if="'Postal' != draft.delivery_service_type" >
                    <div class="address_details_wrapper flex_adj">
                        <header>
                            <h2>PICKUP DETAILS</h2>
                        </header>
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="pickup name"
                                            :rules="`required|max:${max3}`"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Full Name
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    :placeholder="`Enter Character Limit ${max3}`"
                                                    v-model="draft.pickup_name"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_name == '' || draft.pickup_name == null)
                                                        )
                                                        ? 'dynamic_data_trigger warning-border'
                                                        : 'dynamic_data_trigger'
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                    @focus="show_pickup_suggestion = true"
                                                    @blur="hideSuggestions"
                                                    autocomplete="new-password"
                                                    :maxlength="max3"
                                                />
                                                <div
                                                    class="dynamic_data_wrapper"
                                                    v-if="show_pickup_suggestion"
                                                >
                                                    <span
                                                        v-for="(address,
                                                        i) in filteredPickupAddress"
                                                        :key="'pickup_address_' + i"
                                                        @click="populatePickupAddress(address)"
                                                    >
                                                        {{ address.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="pickup company name"
                                            :rules="`required|max:${max3}`"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Company Name
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    :placeholder="`Enter Character Limit ${max3}`"
                                                    v-model="draft.pickup_company"
                                                    autocomplete="new-password"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_company == '' || draft.pickup_company == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :maxlength="max3"
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="pickup email"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Email
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_email"
                                                    autocomplete="new-password"
                                                    :maxlength="max1"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_email == '' || draft.pickup_email == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td colspan="2">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Floor Number
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_floor"
                                                @change="getRate('pickup')"
                                                :maxlength="max1"
                                            />
                                        </div>
                                    </td>
                                </tr> -->
                                <!-- <tr>
                                    <td colspan="2">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Desk Number
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_desk"
                                                @change="getRate('pickup')"
                                                :maxlength="max1"
                                            />
                                        </div>
                                    </td>
                                </tr> -->
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="pickup address_1"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Address 1
                                                    <span>*</span>
                                                    <em class="lite-color"
                                                        >Building</em
                                                    >
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_address_1"
                                                    @change="getRate('pickup')"
                                                    autocomplete="new-password"
                                                    :maxlength="max1"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_address_1 == '' || draft.pickup_address_1 == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="isDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <!-- <ValidationProvider name="pickup address_2" rules="required|max:60" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Address 2
                                                <em class="lite-color">Street Landmark</em>
                                                <span v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_address_2"
                                                autocomplete="new-password"
                                                @change="getRate('pickup')"
                                                :maxlength="max1"
                                                :disabled="isDisabled"
                                                :class="
                                                    draft.carrier_code == 'DHL' && (draft.pickup_address_2 == '' || draft.pickup_address_2 == null)
                                                        ? 'warning-border'
                                                        : ''
                                                "
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="pickup suburb"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Suburb
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_suburb"
                                                    @change="getRate('pickup')"
                                                    autocomplete="new-password"
                                                    :maxlength="max"
                                                    :disabled="isDisabled"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_suburb == '' || draft.pickup_suburb == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider
                                            name="pickup city"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    City
                                                    <!-- <span>*</span> -->
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_city"
                                                    @change="getRate('pickup')"
                                                    autocomplete="new-password"
                                                    :maxlength="max"
                                                    :class="
                                                        draft.carrier_code == 'DHL' && (draft.pickup_city == '' || draft.pickup_city == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="isDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="pickup state"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    State
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_state"
                                                    @change="getRate('pickup')"
                                                    autocomplete="new-password"
                                                    :maxlength="max"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_state == '' || draft.pickup_state == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="isDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider
                                            name="pickup postal_code"
                                            rules="required|max:10"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Postal Code
                                                    <!-- <span>*</span> -->
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 10"
                                                    v-model="draft.pickup_postal_code"
                                                    @change="getRate('pickup')"
                                                    autocomplete="new-password"
                                                    :maxlength="max2"
                                                    :disabled="isDisabled"
                                                    :class="['ZOOM2U','STC','COURIERSPLEASE'].includes(draft.carrier_code) && (draft.pickup_postal_code == '' || draft.pickup_postal_code == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="pickup country"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Country
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_country"
                                                    @change="getRate('pickup')"
                                                    autocomplete="new-password"
                                                    :maxlength="max"
                                                    :disabled="isDisabled"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_country == '' || draft.pickup_country == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="pickup phone"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Phone
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.pickup_phone"
                                                    autocomplete="new-password"
                                                    :maxlength="max"
                                                    :disabled="isDisabled"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.pickup_phone == '' || draft.pickup_phone == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <!-- <ValidationProvider name="pickup mobile" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Mobile
                                                <span v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_mobile"
                                                autocomplete="new-password"
                                                :maxlength="max"
                                                :disabled="isDisabled"
                                                :class="
                                                    draft.carrier_code == 'DHL' && (draft.pickup_mobile == '' || draft.pickup_mobile == null)
                                                        ? 'warning-border'
                                                        : ''
                                                "
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <!-- <ValidationProvider name="pickup branch name" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Branch Name
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_branch"
                                                autocomplete="new-password"
                                                :maxlength="max"
                                                :disabled="isDisabled"
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="address_details_wrapper flex_adj">
                        <header>
                            <h2>DELIVERY DETAILS</h2>
                            <!-- <a class="yellow_bt" @click="viewAddressBook('delivery')">
                                VIEW ADDRESS BOOK
                                <i class="material-icons">account_box</i>
                            </a>
                            <div class="selected_count">
                                <i class="material-icons">account_circle</i>
                                <span>{{ delivery_addresses_count }} Selected</span>
                            </div> -->
                        </header>
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="delivery name"
                                            :rules="`required|max:${max3}`"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Full Name
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    :placeholder="`Enter Character Limit ${max3}`"
                                                    v-model="draft.delivery_name"
                                                    @focus="
                                                        show_destination_suggestion = true
                                                    "
                                                    @blur="hideSuggestions"
                                                    autocomplete="new-password"
                                                    :maxlength="max3"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.delivery_name == '' || draft.delivery_name == null)
                                                        )
                                                        ? 'dynamic_data_trigger warning-border'
                                                        : 'dynamic_data_trigger'
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                />
                                                <div
                                                    class="dynamic_data_wrapper"
                                                    v-if="show_destination_suggestion"
                                                >
                                                    <span
                                                        v-for="(address, i) in filteredDeliveryAddress"
                                                        :key="'delivery_address_' + i"
                                                        @click="populateDeliveryAddress(address)"
                                                    >
                                                        {{ address.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <!-- <ValidationProvider name="delivery_company" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Company Name
                                            </label>
                                            <input
                                                type="text"
                                                :placeholder="`Enter Character Limit ${max3}`"
                                                v-model="draft.delivery_company"
                                                autocomplete="new-password"
                                                :maxlength="max3"
                                                :disabled="enable_edit_var == false"
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="delivery email"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Email
                                                    <span v-if="['MYPOST', 'DHL'].includes(draft.carrier_code)">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_email"
                                                    autocomplete="new-password"
                                                    :maxlength="max1"
                                                    :class="['DHL','ZOOM2U','MYPOST'].includes(draft.carrier_code) && (draft.delivery_email == '' || draft.delivery_email == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td colspan="2">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Floor Number
                                            </label> -->
                                            <!--  <input type="text" placeholder="Street Address" v-model="draft.delivery_address_1" ref='delivery_address_1' @focus="fetchLocationApi('delivery_address_1')" autocomplete="new-password" /> -->
                                            <!-- <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_floor"
                                                @change="getRate('delivery')"
                                                autocomplete="new-password"
                                                :maxlength="max1"
                                                :disabled="enable_edit_var == false"
                                            />
                                        </div>
                                    </td>
                                </tr> -->
                                <!-- <tr>
                                    <td colspan="2">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Desk Number
                                            </label> -->
                                            <!--  <input type="text"  placeholder="Street Address" v-model="draft.delivery_address_1"   ref='delivery_address_1' @focus="fetchLocationApi('delivery_address_1')"  autocomplete="new-password"   /> -->
                                            <!-- <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_desk"
                                                @change="getRate('delivery')"
                                                autocomplete="new-password"
                                                :maxlength="max1"
                                                :disabled="enable_edit_var == false"
                                            />
                                        </div>
                                    </td>
                                </tr> -->
                                <tr>
                                    <td colspan="2">
                                        <ValidationProvider
                                            name="delivery address_1"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Address 1
                                                    <span>*</span>
                                                    <em class="lite-color">Building</em>
                                                </label>
                                                <!--  <input type="text"  placeholder="Street Address" v-model="draft.delivery_address_1"   ref='delivery_address_1' @focus="fetchLocationApi('delivery_address_1')"  autocomplete="new-password"   /> -->
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_address_1"
                                                    @change="getRate('delivery')"
                                                    autocomplete="new-password"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.delivery_address_1 == '' || draft.delivery_address_1 == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :maxlength="max1"
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <!-- <ValidationProvider name="delivery address_2" rules="required|max:60" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Address 2
                                                <em class="lite-color">Street Landmark</em>
                                                <span v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_address_2"
                                                autocomplete="new-password"
                                                @change="getRate('delivery')"
                                                :maxlength="max1"
                                                :disabled="enable_edit_var == false"
                                                :class="
                                                    draft.carrier_code == 'DHL' && (draft.delivery_address_2 == '' || draft.delivery_address_2 == null)
                                                        ? 'warning-border'
                                                        : ''
                                                "
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="delivery suburb"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Suburb
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_suburb"
                                                    autocomplete="new-password"
                                                    @change="getRate('delivery')"
                                                    :maxlength="max"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.delivery_suburb == '' || draft.delivery_suburb == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider
                                            name="delivery city"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_city"
                                                    autocomplete="new-password"
                                                    @change="getRate('delivery')"
                                                    :class="
                                                        draft.carrier_code == 'DHL' &&
                                                        (
                                                            draft.delivery_city == '' ||
                                                            draft.delivery_city == null
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :maxlength="max"
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="delivery state"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    State
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_state"
                                                    autocomplete="new-password"
                                                    @change="getRate('delivery')"
                                                    :maxlength="max"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.delivery_state == '' || draft.delivery_state == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider
                                            name="delivery postal_code"
                                            rules="required|max:10"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Postal Code
                                                    <!-- <span>*</span> -->
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 10"
                                                    v-model="draft.delivery_postal_code"
                                                    autocomplete="new-password"
                                                    @change="getRate('delivery')"
                                                    :maxlength="max2"
                                                    :disabled="enable_edit_var == false"
                                                    :class="
                                                        errors[0] || (
                                                            ['ZOOM2U','STC','MYPOST','DBL'].includes(draft.carrier_code) &&
                                                            (draft.delivery_postal_code == '' || draft.delivery_postal_code == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="delivery country"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Country
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_country"
                                                    autocomplete="new-password"
                                                    @change="getRate('delivery')"
                                                    :maxlength="max"
                                                    :disabled="
                                                        enable_edit_var == false
                                                    "
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) &&
                                                            (draft.delivery_country == '' || draft.delivery_country == null)
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Add to Address Book
                                            </label>
                                            <input
                                                type="radio"
                                                value="internal"
                                                v-model="save_address.internal"
                                                :checked="save_address.internal"
                                            />Internal
                                            <input
                                                type="radio"
                                                value="external"
                                                v-model="save_address.external"
                                                :checked="save_address.internal"
                                            />External
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="delivery phone"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Phone
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.delivery_phone"
                                                    autocomplete="new-password"
                                                    :maxlength="max"
                                                    :class="
                                                        errors[0] || (
                                                            ['DHL','ZOOM2U','STC','MYPOST','COURIERSPLEASE','TOLLPRIORITY','DBL'].includes(draft.carrier_code) && 
                                                            (draft.delivery_phone == null || draft.delivery_phone == '')
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="enable_edit_var == false"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <!-- <ValidationProvider name="delivery mobile" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Mobile
                                                <span v-if="draft.carrier_code == 'DHL'">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_mobile"
                                                autocomplete="new-password"
                                                :maxlength="max"
                                                :disabled="enable_edit_var == false"
                                                :class="
                                                    draft.carrier_code == 'DHL' && (draft.delivery_mobile == '' || draft.delivery_mobile == null)
                                                        ? 'warning-border'
                                                        : ''
                                                "
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <!-- <ValidationProvider name="delivery branch" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Branch Name
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_branch"
                                                autocomplete="new-password"
                                                :maxlength="max"
                                                :disabled="enable_edit_var == false"
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </section>

                <section class="new_flexed_class"  v-if="'Postal' != draft.delivery_service_type">
                    <div class="address_details_wrapper flex_adj">
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td>
                                        <!-- <ValidationProvider name="pickup suburb" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Shipment Pickup Date
                                                <span>*</span>
                                            </label>
                                            <flat-pickr
                                                class="date_for_pos_fixed"
                                                v-model="draft.pickup_date"
                                                :config="configDate"
                                                placeholder="Date"
                                                name="birthdate"
                                                :disabled="enable_edit_var == false"
                                                @on-change="checkDateIfToday($event)"
                                            ></flat-pickr>
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                    <td>
                                        <ValidationProvider
                                            name="pickup city"
                                            rules="required|max:30"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Shipment Pickup Time
                                                    <span>*</span>
                                                </label>
                                                <flat-pickr
                                                    :config="configTime"
                                                    placeholder="Select Time"
                                                    name="birthtime"
                                                    v-model="draft.pickup_time"
                                                    :disabled="enable_edit_var == false"
                                                ></flat-pickr>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <div class="label_and_element_wrapper">
                                            <label></label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    v-model="today_pickup"
                                                    :checked="today_pickup"
                                                    @change="toggleTodayPickup($event)"
                                                />
                                                Pickup Today
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="address_details_wrapper flex_adj">
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td>
                                        <ValidationProvider
                                            name="delivery state"
                                            rules="required|max:30"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Office Closed By
                                                    <span>*</span>
                                                </label>
                                                <flat-pickr
                                                    v-model="draft.office_close_time"
                                                    :config="configTime"
                                                    placeholder="Select Time"
                                                    name="birthtime"
                                                    :disabled="enable_edit_var == false"
                                                ></flat-pickr>
                                            </div>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <!-- <ValidationProvider name="delivery postal_code" rules="required|max:10" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Customer Reference Number
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 10"
                                                v-model="draft.reference_no"
                                                autocomplete="new-password"
                                                :maxlength="max2"
                                                :disabled="enable_edit_var == false"
                                                @change="customerRefenceNo()"
                                            />
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </section>

                <header  v-if="'Postal' != draft.delivery_service_type">
                    <h2 class="_line_item">PACKAGE DETAILS</h2>
                </header>
                <div class=" sub_form_sections1"  v-if="'Postal' != draft.delivery_service_type">
                    <div class=" add_remove_btns1">
                        <i
                            class="material-icons add_bt1"
                            @click="addPackageDetails"
                            v-show="package_details_var == false && enable_edit_var != false"
                        >
                            arrow_drop_down
                        </i>
                        <i
                            class="material-icons remove_bt"
                            @click="removePackageDetails"
                            v-show="package_details_var == true && enable_edit_var != false"
                        >
                            arrow_drop_up
                        </i>
                    </div>
                </div>

                <div
                    class="_line_item_section"
                    v-show="(draft.type_of_good == goodsParcel && parcel_var == true) || package_details_var == true"
                >
                    <section>
                        <div
                            class="form_section1 sub_form_sections _sur_overflow"
                            v-for="(draft, i) in draft.courierItem"
                            :key="'item_container' + i"
                        >
                            <div class="add_remove_btns">
                                <a
                                    class="add_bt"
                                    @click="addItemFields"
                                    v-if="enable_edit_var != false"
                                ></a>
                                <a
                                    class="remove_bt"
                                    v-if="i > 0 && enable_edit_var != false"
                                    @click="removeItemField(i)"
                                ></a>
                            </div>

                            <div class="_sur_container">
                                <!--this isfirst rowof theform-->
                                <div class="_sur_flex_container">
                                    <div class="_sur_flex_boxes">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Item Reference
                                                <span></span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 60"
                                                v-model="draft.item_reference"
                                                :maxlength="max"
                                                :disabled="enable_edit_var == false"
                                                style="width: 120px;"
                                            />
                                        </div>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="item_type"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label style="">
                                                    Package Type
                                                    <!-- <span>*</span> -->
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    v-model="draft.item_type"
                                                    :maxlength="max"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 100px;"
                                                    :class="errors[0] ? 'warning-border' : ''"
                                                />

                                                <input
                                                    type="hidden"
                                                    name="booking_id"
                                                    value="draft.booking_id"
                                                />
                                                <input
                                                    type="hidden"
                                                    name="id"
                                                    value="draft.id"
                                                />
                                                <!-- <input type="hidden" v-model="draft.booking_id"  /> -->
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="item description"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Item Description
                                                    <!-- <span>*</span> -->
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 20"
                                                    v-model="draft.item_description"
                                                    :maxlength="max"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 175px;"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Unit(cm)
                                                <span></span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 5"
                                                v-model="draft.item_charge_unit"
                                                :maxlength="max1"
                                                :disabled="enable_edit_var == false"
                                                style="width:60px;"
                                            />
                                        </div>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <ValidationProvider
                                            name="item_type"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Quantity
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 5"
                                                    v-model="draft.item_qty"
                                                    :maxlength="max1"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 60px;"
                                                    :class="
                                                        errors[0] ||
                                                        (
                                                            draft.item_qty == '' ||
                                                            draft.item_qty == null
                                                        )
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <ValidationProvider
                                            name="item_weight"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Weight
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 5"
                                                    v-model="draft.item_weight"
                                                    :maxlength="max1"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 71px;"
                                                    :class="errors[0] ? 'warning-border' : ''"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <ValidationProvider
                                            name="item_length"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    L(cm)
                                                    <span>*</span>
                                                </label>

                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 5"
                                                    v-model="draft.item_length"
                                                    @change="updatem3(i, 'l', $event)"
                                                    :maxlength="max1"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 71px;"
                                                    :class="errors[0] || !draft.item_length ? 'warning-border' : ''"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <ValidationProvider
                                            name="item_width"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    W(cm)
                                                    <span>*</span>
                                                </label>
                                                <!--  <input type="text" placeholder="Enter Character Limit 5"  :value="draft.item_width" @change="updatem3(i,'w',$event)" :maxlength="max1" :disabled="enable_edit_var == false" style="width: 71px;" class="errors[0] ? 'warning-border':''"/> -->
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 5"
                                                    v-model="draft.item_width"
                                                    @change="updatem3(i, 'w', $event)"
                                                    :maxlength="max1"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 71px;"
                                                    :class="errors[0] || !draft.item_width ? 'warning-border' : ''"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>

                                    <div class="_sur_flex_boxes small">
                                        <ValidationProvider
                                            name="item_height"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    H(cm)
                                                    <span>*</span>
                                                </label>
                                                <!-- <input type="text" placeholder="Enter Character Limit 5" :value="draft.item_height" @change="updatem3(i,'h',$event)" :maxlength="max1" :disabled="enable_edit_var == false" style="width: 71px;" class="errors[0] ? 'warning-border':''"/> -->
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 5"
                                                    v-model="draft.item_height"
                                                    @change="updatem3(i, 'h', $event)"
                                                    :maxlength="max1"
                                                    :disabled="enable_edit_var == false"
                                                    style="width: 71px;"
                                                    :class="errors[0] || !draft.item_height ? 'warning-border' : ''"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                m3
                                                <span></span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 5"
                                                v-model="draft.item_m3"
                                                :maxlength="max1"
                                                disabled
                                                style="width: 88px;"
                                            />
                                        </div>
                                    </div>
                                    <div class="_sur_flex_boxes small">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Cubic kg
                                                <span></span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Character Limit 5"
                                                v-model="draft.item_cubic_kg"
                                                :maxlength="max1"
                                                :disabled="enable_edit_var == false"
                                                style="width: 72px;"
                                            />
                                        </div>
                                    </div>
                                    <div class="_sur_flex_boxes adj_1920">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                DG
                                                <span></span>
                                            </label>
                                            <input
                                                type="checkbox"
                                                v-model="draft.item_is_dg"
                                                true-value="1"
                                                false-value="0"
                                                :disabled="enable_edit_var == false"
                                                class="chk_standard"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="_sur_flex_container">
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="product code"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Product Code
                                                    <!-- <span>*</span> -->
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 60"
                                                    :minlength="5"
                                                    v-model="draft.product_code"
                                                    :disabled="itemDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="export reason_type"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Export Reason
                                                    <span>*</span>
                                                </label>

                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 5"
                                                    v-model="draft.export_reason_type"
                                                    :maxlength="max1"
                                                    :disabled="itemDisabled"
                                                    :class="
                                                        draft.item_type == 'Parcel' &&
                                                        draft.carrier_code == 'DHL' &&
                                                        (draft.export_reason_type == '' || draft.export_reason_type == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="export reason type"
                                            rules="required"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Manufacture
                                                    <span>*</span>
                                                </label>
                                                <!-- <input type="text" placeholder="Enter" :maxlength="2"  style="width: 82px;position: relative;left: -25px;"  v-model="draft.country_code" :class="errors[0] ? 'warning-border':''" /> -->
                                                <select
                                                    v-model="draft.country_code"
                                                    :maxlength="2"
                                                    :class="
                                                        draft.item_type == 'Parcel' &&
                                                        draft.carrier_code == 'DHL' &&
                                                        draft.country_code == ''
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="itemDisabled"
                                                >
                                                    <option
                                                        v-for="(country, i) in countries"
                                                        :key="'countries' + i"
                                                        :value="country.code"
                                                    >
                                                        {{ country.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="product_code"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Commercial Value
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Value"
                                                    :maxlength="max"
                                                    style="width: 112px"
                                                    v-model="draft.commercial_value"
                                                    :class="
                                                        draft.item_type == 'Parcel' &&
                                                        draft.carrier_code == 'DHL' &&
                                                        (draft.commercial_value == '' || draft.commercial_value == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="itemDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Insured Value
                                                <span></span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter Value"
                                                :maxlength="max"
                                                style="width: 105px;"
                                                v-model="draft.insured_value"
                                                :disabled="itemDisabled"
                                            />
                                        </div>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="export_reason_type"
                                            rules="required"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Currency
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 2"
                                                    :maxlength="3"
                                                    style="width: 72px;"
                                                    v-model="draft.currency"
                                                    :class="
                                                        draft.item_type == 'Parcel' &&
                                                        draft.carrier_code == 'DHL' &&
                                                        (draft.currency == '' || draft.currency == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    :disabled="itemDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="export_reason_type"
                                            rules="required|max:60"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Invoice No
                                                    <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Character Limit 2"
                                                    :maxlength="max"
                                                    style="width: 130px;"
                                                    v-model="draft.invoice_no"
                                                    :class="
                                                        draft.item_type == 'Parcel' &&
                                                        draft.carrier_code == 'DHL' &&
                                                        (draft.invoice_no == '' || draft.invoice_no == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                    class="invoice_number"
                                                    :disabled="itemDisabled"
                                                />
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="_sur_flex_boxes">
                                        <ValidationProvider
                                            name="pickup suburb"
                                            rules="required|max:30"
                                            v-slot="{ errors }"
                                            slim
                                        >
                                            <div class="label_and_element_wrapper width_adj">
                                                <label>
                                                    Invoice Date
                                                    <span>*</span>
                                                </label>
                                                <flat-pickr
                                                    class="date_for_pos_fixed"
                                                    v-model="draft.invoice_date"
                                                    :config="configDate"
                                                    placeholder="Date"
                                                    name="birthdate"
                                                    :disabled="itemDisabled"
                                                    :class="
                                                        draft.item_type == 'Parcel' &&
                                                        draft.carrier_code == 'DHL' &&
                                                        (draft.invoice_date == '' || draft.invoice_date == null)
                                                        ? 'warning-border'
                                                        : ''
                                                    "
                                                ></flat-pickr>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section class="new_flexed_class" v-if="'Postal' != draft.delivery_service_type" >
                    <div class="address_details_wrapper flex_adj">
                        <header>
                            <h2>DESCRIPTION OF GOODS</h2>
                        </header>

                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td>
                                        <!-- <ValidationProvider name="goods description" rules="required|max:30" v-slot="{ errors }" slim>-->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Enter description of goods
                                                <!-- <span>*</span> -->
                                            </label>
                                            <textarea
                                                placeholder="Enter Character Limit 70"
                                                v-model="draft.goods_description"
                                                :maxlength="max3"
                                            ></textarea>
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="address_details_wrapper flex_adj" v-if="'Postal' != draft.delivery_service_type">
                        <header>
                            <h2>PICKUP INSTRUCTIONS</h2>
                        </header>
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td>
                                        <!-- <ValidationProvider name="pickup instructions" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Enter pickup instructions
                                                <!-- <span>*</span> -->
                                            </label>
                                            <textarea
                                                placeholder="Description here Character Limit 70"
                                                v-model="draft.pickup_instructions"
                                                :maxlength="max3"
                                            ></textarea>
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="address_details_wrapper flex_adj" v-if="'Postal' != draft.delivery_service_type">
                        <header>
                            <h2>DELIVERY INSTRUCTIONS</h2>
                        </header>
                        <div class="form_section1">
                            <table>
                                <tr>
                                    <td>
                                        <!-- <ValidationProvider name="pickup instructions" rules="required|max:30" v-slot="{ errors }" slim> -->
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Enter delivery instructions
                                                <!-- <span>*</span> -->
                                            </label>
                                            <textarea
                                                placeholder="Description here Character Limit 70"
                                                v-model="draft.remarks"
                                                :maxlength="max3"
                                            ></textarea>
                                        </div>
                                        <!-- </ValidationProvider> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </section>
            </ValidationObserver>
            <section class="centered"  v-if="'Postal' != draft.delivery_service_type">
                <a
                    class="link_bt bt_cancel"
                    @click="$router.push('/pages/Dashboard')"
                    >Cancel</a
                >
                <a
                    class="link_bt bt_draft"
                    @click="draftsubmit"
                    :disabled="save_btn.disabled"
                    >{{ save_btn.value }}</a
                >
                <!--
                <a
                    class="link_bt bt_save"
                    @click="thresholdchecksubmit"
                    :disabled="submit_btn.disabled"
                    >{{ submit_btn.value }}</a
                >
                -->
                <a
                    class="link_bt bt_save"
                    @click="thresholdcheckdipatchsubmit"
                    :disabled="dispatchsubmit_btn.disabled"
                    >{{ dispatchsubmit_btn.value }}</a
                >
            </section>
        <div
            class="black-overlay address-book-modal"
            v-if="
                company_address_book.pickup ||
                company_address_book.delivery ||
                personal_address_book.pickup ||
                personal_address_book.delivery
            "
        >
            <div class="box-modal">
                <div class="body">
                    <AddressBookModal
                        :selectedAddresses="[
                            { id: draft.temp_pickup_address_id },
                        ]"
                        v-if="company_address_book.pickup"
                        @closeModal="closeModal"
                        type="pickup"
                        @selectedAddress="populatePickupAddress"
                    />
                    <AddressBookModal
                        v-if="company_address_book.delivery"
                        @closeModal="closeModal"
                        type="delivery"
                        :callfrom="draft.entry_type"
                        @selectedAddress="populateDeliveryAddress"
                        :selectedAddresses="draft.delivery_address"
                    />
                    <PersonalAddressBookModal
                        :selectedAddresses="[
                            { id: draft.temp_pickup_address_id },
                        ]"
                        v-if="personal_address_book.pickup"
                        @closeModal="closeModal"
                        type="pickup"
                        @selectedAddress="populatePickupAddress"
                    />
                    <PersonalAddressBookModal
                        v-if="personal_address_book.delivery"
                        @closeModal="closeModal"
                        type="delivery"
                        :callfrom="draft.entry_type"
                        @selectedAddress="populateDeliveryAddress"
                        :selectedAddresses="draft.delivery_address"
                    />
                </div>
            </div>
        </div>
        <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
            <div class="box-modal printPops">
                <div class="body">
                    <ViewConsignment
                        @closeModal="closeConsignment"
                        :booking_id="viewconsignmentpopup_booking_id"
                        v-if="viewconsignmentpopup_booking_id"
                    />
                </div>
            </div>
        </div>
        <div class="black-overlay" v-if="show_approval_reference == true">
            <div class="box-modal smallPops">
                <div class="body">
                    <ApprovalReferencePopup
                        @closeModal="closeModalApprovalReference"
                        @submitmanager="populateManagerName"
                        :managerName="draft.manager_name"
                    />
                </div>
            </div>
        </div>
        <!-- change pickup address -->
        <div class="black-overlay" v-if="changePickup">
            <div class="box-modal" style="background:#004a7c; height: 750px;">
                <div class="body" style="height: 730px;">
                    <ChangePickupAddress
                        v-if="changePickup"
                        :countries="lcountries"
                        @closeModal="changePickup = false"
                        @submit="populatePickupAddressApi"
                    />
                </div>
            </div>
        </div>
        <!-- change gl code -->
        <div class="black-overlay" v-if="changeGlCode">
            <div class="box-modal smallPops" style="background:#004a7c;">
                <div class="body">
                    <ChangeGlcode
                        v-if="changeGlCode"
                        @closeModal="changeGlCode = false"
                        @submit="populateGlCodeApi"
                    />
                </div>
            </div>
        </div>
        <!-- change pickup address -->
        <div class="black-overlay" v-if="addressHistoryList">
            <div class="box-modal" style="background:#004a7c; height: 750px;">
                <div class="body" style="height: 730px;">
                    <AddressHistoryList
                        v-if="addressHistoryList"
                        :showAddressList="showAddressList"
                        @closeModal="addressHistoryList = false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import flatPickr from "vue-flatpickr-component";
import ChangePickupAddress from "./ChangePickupAddress.vue";
import AddressHistoryList from "./AddressHistoryList.vue";
import AddressBookModal from "./AddressBookModal.vue";
import PersonalAddressBookModal from "./PersonalAddressBookModal.vue";
import ViewConsignment from "./ViewConsignment.vue";
import ApprovalReferencePopup from "./ApprovalReferencePopup.vue";
import moment from "moment-timezone";
import ChangeGlcode from "./ChangeGlCode.vue";
import jQuery from "jquery";
import "flatpickr/dist/flatpickr.css";

const $ = jQuery;

export default {
    name: "QuoteAndBook",
    components: {
        flatPickr,
        AddressHistoryList,
        ChangePickupAddress,
        AddressBookModal,
        PersonalAddressBookModal,
        ViewConsignment,
        ApprovalReferencePopup,
        ChangeGlcode,
    },
    computed: {
        filteredPickupAddress() {
            return this.address_list.filter((element) => {
                if (element.name.toLowerCase().includes(this.draft.pickup_name.toLowerCase())) {
                    return element;
                }
            });
        },
        filteredDeliveryAddress() {
            return this.address_list.filter((element) => {
                if (element.name.toLowerCase().includes(this.draft.delivery_name.toLowerCase())) {
                    return element;
                }
            });
        },
        isDisabled() {
            return (
                this.auth().user_type == "user" || this.enable_edit_var == false
            );
        },
        itemDisabled() {
            return (
                this.enable_edit_var == false ||
                this.draft.carrier_service_type != this.serviceInternational ||
                this.draft.carrier_code != "DHL" ||
                this.draft.type_of_good == this.goodsDocument
            );
        },
    },
    data() {
        return {
            hub_admin: false,
            disable_gl_code: true,
            gl_code_placeholder: 'GL Code',
            updateType: false,
            show_approval_reference: false,
            findcourier_btn_edit: false,
            findcourier_btn: {
                length: false,
                disabled: false,
                value: "FIND COURIERS",
            },
            has_standard: 1,
            viewconsignmentpopup_booking_id: "",
            delivery_addresses_count: 0,
            showAddressList: {
                all:[],
                sucess:[],
                failed:[],
                ongoing:[],
            },
            pickup_rate:{
                all:[],
                sucess:[],
                failed:[],
                ongoing:[],
            },
            deliver_rate:{
                all:[],
                sucess:[],
                failed:[],
                ongoing:[],
            },
            max: 30,
            max1: 60,
            max2: 10,
            max3: 70,
            max4: 100,
            max5: 120,
            max6: 200,
            configDate: {
                format: "d-m-Y",
                altFormat: "d-m-Y",
                altInput: true,
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            changePickup: false,
            addressHistoryList: false,
            changeGlCode: false,
            showEditContact: false,
            courier_list: false,
            error_class: false,
            edit_mode: false,
            enable_edit_var: false,
            package_details_var: false,
            parcel_var: true,
            volume: "",
            servicetypes: "",
            courier: "",
            ratecouriers: [],
            is_carrier_preference: "",
            threshold_identifier: "",
            final_weight_flag: "",
            count_weight: 0,
            draft: {
                custom_code: "",
                request_date: moment(new Date()).format("YYYY-MM-DD"),
                entry_type: "single",
                requester_name: "",
                branch_id: "",
                type_of_good: "",
                carrier_service_type: "",
                delivery_service_type: "",
                mode_of_dispatch: "",
                carrier_code: "",
                provider_id: "",
                service_id: "",
                p: "",
                t: "",
                a: "",
                e: "",
                o: "",
                has_priority: 0,
                has_emergency: 0,
                reference_no: "",
                temp_pickup_address_id: "",
                pickup_name: "",
                pickup_company: "",
                pickup_branch: "",
				pickup_floor: '',
				pickup_desk: '',
                pickup_address_1: "",
                pickup_address_2: "",
                pickup_postal_code: "",
                pickup_city: "",
                pickup_suburb: "",
                pickup_state: "",
                pickup_country: "",
                pickup_country_code: "",
                pickup_phone: "",
                pickup_mobile: "",
                pickup_email: "",
                delivery_name: "",
                delivery_company: "",
                delivery_branch: "",
				delivery_floor: '',
				delivery_desk: '',
                delivery_address_1: "",
                delivery_address_2: "",
                delivery_postal_code: "",
                delivery_city: "",
                delivery_suburb: "",
                delivery_state: "",
                delivery_country: "",
                delivery_country_code: "",
                delivery_phone: "",
                delivery_mobile: "",
                delivery_email: "",
                goods_description: "",
                pickup_instructions: "",
                remarks: "",
                delivery_address: [],
                booking_type: "Courier",
                priority: "Standard",
                sla: "",
                courierItem: [
                    {
                        item_reference: "NA",
                        item_type: "",
                        item_description: "",
                        item_charge_unit: "cm",
                        item_qty: 1,
                        item_weight: "",
                        item_length: "",
                        item_width: "",
                        item_height: "",
                        item_m3: 0,
                        item_cubic_kg: "",
                        item_is_dg: 0,
                        product_code: "",
                        export_reason_type: "Permanent",
                        country_code: "AU",
                        commercial_value: "",
                        insured_value: "",
                        currency: "AUD",
                        invoice_no: "",
                        invoice_date: moment(new Date()).format("YYYY-MM-DD"),
                        booking_id: "",
                    },
                ],
                weight: "",
                pickup_date: moment().format("YYYY-MM-DD"),
                pickup_time: moment().format("HH:mm:ss"),
                office_close_time: "17:00:00",
				matter_number_id: '',
				gl_code: '',
				is_point_to_point: false,
				is_default_courier_messenger: false,
				pickup_address_category: 'internal',
				delivery_address_category: 'personal',
                id: '',
            },
            company_address_book: {
                pickup: false,
                delivery: false,
            },
            personal_address_book: {
                pickup: false,
                delivery: false,
            },
            provider_details: "",
            show_pickup_suggestion: false,
            show_destination_suggestion: false,
            pickupAddressCount: 0,
            selectedAddresses: [],
            managerName: [],
            branches: [],
            dispatches: [],
            goods: [],
            providers: [],
            services: [],
            address_list: [],
            goodsTypes: [],
            countries: [],
            save_btn: {
                disabled: false,
                value: "SAVE AS DRAFT",
            },
            save_postal_btn: {
                disabled: false,
                value: "SAVE JOB",
            },
            submit_btn: {
                disabled: false,
                value: "Submit",
            },
            dispatchsubmit_btn: {
                disabled: false,
                value: "Submit & Dispatch",
            },
            save_address: {
                internal: false,
                external: false,
            },
			today_pickup: true,
			matter_numbers: [{id: '', label: ''}],
            temp_pickup_date: '',
            temp_pickup_time: '',
            window_pickup_from_time: '08:00:00',
            window_pickup_to_time: '17:00:00',
            lcountries: [],
            goodsDocument: process.env.VUE_APP_TYPE_OF_GOODS_DOCUMENT, 
            goodsParcel: process.env.VUE_APP_TYPE_OF_GOODS_PARCEL,
            serviceInternational: process.env.VUE_APP_TYPE_OF_SERVICE_INTERNATIONAL,
            preparingForm: false,
        };
    },

    async created() {

        this.$emit("breadCrumbData", ["Create Booking", "Quote And Book"]);

        if (this.draft.has_emergency == 0 && this.draft.has_priority == 0) {
            this.has_standard = 3;
        }

        this.preparingForm = true;

        await this.fetchAddresses();

        this.preparingForm = false;

        this.fetchPackageType();
        this.fetchCarrierServiceType();
        this.fetchBranch();
        this.fetchCountryCode();
		this.fetchMatterNumber();
        this.getCountries();

        if (this.$route.params.id == undefined) {
            this.enable_edit_var = true;
            this.parcel_var = true;
        }

        //EDIT
        if (this.$route.params.id != undefined) {

            this.edit_mode = true;
            this.enable_edit_var = true; //if this.enable_edit_var=false , we cannot type in the textbox.
            await this.populateDraft(this.$route.params.id);

        } else {

            try {
                const response = await this.axios.get(`/api/user/data`);
                this.draft.requester_name = response.data.msg.name;
                this.draft.branch_id = response.data.msg.branch.id;
                // this.draft.type_of_good = response.data.msg.goods.id;
                // this.fetchDispatch();
                // if(this.draft.type_of_good){
                //   this.axios.get("/api/goods/"+this.draft.type_of_good)
                //   .then(response => {
                //     console.log(response);
                //     this.draft.item_type = response.data.goods.type;
                //     this.draft.item_weight = response.data.goods.weight;
                //     this.draft.item_length = response.data.goods.length;
                //     this.draft.item_width = response.data.goods.width;
                //     this.draft.item_height = response.data.goods.height;
                //     this.draft.item_m3 = (((this.draft.item_length)*(this.draft.item_width)*(this.draft.item_height))*1)/100;
                //     this.draft.item_cubic_kg = response.data.goods.cubic_kg;
                //     this.draft.item_is_dg = response.data.goods.is_dg;
                //     this.draft.item_is_food = response.data.goods.is_food;
                //     this.volume = response.data.goods.volume;
                //   })
                //   .catch(error => {
                //     console.log(error);
                //   });
                // }
                await this.fetchUserData();
                if (!this.hub_admin) {
                    this.fetchGlCode();
                }
            }
            catch (error) {
                console.log(error);
            }
        }

        // this.findCouriers();
        // this.fetchPtaeoDetails();
        // this.fetchProviders();
        this.getRate('pickup')

    },
    methods: {
        ...mapActions({
			setCountries: 'country/setCountries',
		}),
        rateCalculator(rateObject) {
            if (!rateObject.success) {
                return;
            }
            const total = rateObject.success.length + rateObject.failed.length

            if (total) {
                return parseFloat(parseFloat(rateObject.success.length / total).toFixed(2) * 100).toFixed(2)
            }

            return;
        },
        async getRate(type = null) {
            const url = "/api/address/rate"
            let address = {};
            if ('pickup' == type) {
                address.type = 'pickup'
                address.floor = this.draft.pickup_floor
                address.desk = this.draft.pickup_desk
                address.address_1 = this.draft.pickup_address_1
                address.address_2 = this.draft.pickup_address_2
                address.suburb = this.draft.pickup_suburb
                address.city = this.draft.pickup_city
                address.state = this.draft.pickup_state
                address.postal_code = this.draft.pickup_postal_code
                address.country = this.draft.pickup_country
                this.pickup_rate = {
                    all:[],
                    sucess:[],
                    failed:[],
                    ongoing:[],
                }
            } else {
                address.type = 'delivery'
                address.floor = this.draft.delivery_floor
                address.desk = this.draft.delivery_desk
                address.address_1 = this.draft.delivery_address_1
                address.address_2 = this.draft.delivery_address_2
                address.suburb = this.draft.delivery_suburb
                address.city = this.draft.delivery_city
                address.state = this.draft.delivery_state
                address.postal_code = this.draft.delivery_postal_code
                address.country = this.draft.delivery_country
                
                this.deliver_rate = {
                    all:[],
                    sucess:[],
                    failed:[],
                    ongoing:[],
                }
            }
            try {
                const response = await this.axios.post(url, address);
                if (response.data.all) {
                    if ('pickup' == type) {
                        this.pickup_rate = response.data
                    } else {
                        this.deliver_rate = response.data
                    }
                }
            } catch (error) {
            }
        },
        fetchLocationApi(type) {
            let options = {
                types: ["geocode"],
            };
            let map = new google.maps.places.Autocomplete(this.$refs[type], {
                componentRestrictions: { country: [] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });

            map.addListener("place_changed", () => {
                let place = map.getPlace();

                this.draft.delivery_address_1 = this.draft.delivery_postal_code = "";

                console.log(JSON.stringify(place));
                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number": {
                            this.draft.delivery_address_1 =
                                component.short_name;
                            break;
                        }

                        case "administrative_area_level_2": {
                          this.draft.delivery_city = component.short_name;
                          break;
                        }

                        case "route": {
                            this.draft.delivery_address_1 = `${
                                this.draft.delivery_address_1
                            } ${component.long_name}`;
                            break;
                        }

                        case "postal_code": {
                            this.draft.delivery_postal_code =
                                component.long_name;
                            //  this.draft.postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                        case "postal_code_suffix": {
                            this.draft.postcode = `${
                                this.draft.delivery_postal_code
                            }-${component.long_name}`;
                            break;
                        }
                        case "locality": {
                            if (this.draft.delivery_city == '') {
                                this.draft.delivery_city = component.short_name;
                            }
                            this.draft.delivery_suburb = component.short_name;
                            break;
                        }

                        case "administrative_area_level_1": {
                            this.draft.delivery_state = component.short_name;
                            break;
                        }
                        case "country":
                            this.draft.delivery_country = component.long_name;
                            this.draft.delivery_country_code = component.short_name;
                            break;
                    }
                }

				this.draft.delivery_address_category = 'personal';
                this.getRate('delivery')
            });
        },
        addPackageDetails() {
            if (this.package_details_var == false) {
                this.package_details_var = true;
            }
            if (this.draft.type_of_good == this.goodsParcel) {
                this.parcel_var = true;
            }
        },
        removePackageDetails() {
            // var i=0;
            if (this.package_details_var == true) {
                this.package_details_var = false;
            }
            this.parcel_var = false;
            //this.draft.courierItem.splice(i<=0);
        },
        addItemFields() {
            this.item_description = "";
            this.invoice_no = "";
            this.carrier_code = "";
            
            if (
                this.draft.carrier_service_type == this.serviceInternational &&
                this.draft.carrier_code == "DHL" &&
                this.draft.type_of_good != this.goodsDocument
            ) {
                this.invoice_no = this.draft.pickup_company;
            }

            this.carrier_code = this.draft.carrier_code;
            
            //For DHL default, good description as per package type (Document and Package)
            //if(this.draft.carrier_code== 'DHL')
            this.item_description = this.draft.courierItem[0].item_type;

            this.draft.courierItem.push({
                item_reference: "NA",
                item_type: this.draft.courierItem[0].item_type,
                item_description: this.item_description,
                item_charge_unit: "cm",
                item_qty: 1,
                item_weight: "",
                item_length: this.draft.courierItem[0].item_length,
                item_width: this.draft.courierItem[0].item_width,
                item_height: this.draft.courierItem[0].item_height,
                item_m3: this.draft.courierItem[0].item_m3,
                item_cubic_kg: this.draft.courierItem[0].item_weight * this.draft.courierItem[0].item_m3,
                item_is_dg: this.draft.courierItem[0].item_is_dg,
                product_code: "",
                export_reason_type: "Permanent",
                country_code: "AU",
                commercial_value: "",
                insured_value: "",
                currency: "AUD",
                invoice_no: this.invoice_no,
                invoice_date: moment(new Date()).format("YYYY-MM-DD"),
                booking_id: "",
                id: "",
                carrier_code: this.carrier_code,
            });
            this.customerRefenceNo();
        },
        removeItemField(i) {
            var id = this.draft.courierItem[i].id;
            if (this.$route.params.id != undefined) {
                this.deleteLineItem(id);
            }
            this.draft.courierItem.splice(i, 1);
        },
        deleteLineItem(id) {
            if (confirm("Are you sure you want to delete this item?")) {
                this.axios
                    .delete("/api/draft/quotebook/delete/" + id)
                    .then((response) => {
                        this.toast.success(response.data.msg);
                        this.fetchDepartment();
                    })
                    .catch((error) => {
                        console.log("error", error.response);
                        this.toast.error();
                    });
            }
        },
        updatem3(i, unit, event) {
            if (unit == "l") {
                this.draft.courierItem[i].item_length = event.target.value;
            }
            if (unit == "w") {
                this.draft.courierItem[i].item_width = event.target.value;
            }
            if (unit == "h") {
                this.draft.courierItem[i].item_height = event.target.value;
            }
            this.draft.courierItem[i].item_m3 = ((this.draft.courierItem[i].item_length * this.draft.courierItem[i].item_width * this.draft.courierItem[i].item_height) / 100).toFixed(3);
            this.draft.courierItem[i].item_cubic_kg = this.draft.courierItem[i].item_m3;
        },
        updateWeight() {
            this.draft.courierItem[0].item_weight = this.draft.weight;
        },
        customerRefenceNo() {
            var refference_no = "";

            if (
                this.draft.carrier_service_type == this.serviceInternational &&
                this.draft.carrier_code == "DHL" &&
                this.draft.type_of_good != this.goodsDocument
            ) {
                if (this.draft.reference_no)
                    refference_no = this.draft.reference_no;
                else refference_no = this.draft.pickup_company;

                var els = document.getElementsByClassName("invoice_number");
                for (var i = 0; i < els.length; i++) {
                    els[i].value = refference_no;
                }

                let j = 0;
                this.draft.courierItem.forEach(() => {
                    this.draft.courierItem[j].invoice_no = refference_no;
                    j++;
                });
            }
        },
        hideSuggestions() {
            setTimeout(() => {
                this.show_pickup_suggestion = false;
                this.show_destination_suggestion = false;
            }, 200);
        },
        async fetchCountryCode() {
            try {
                const response = await this.axios.get('/api/common/countries');
                this.countries = response.data.countries;
                if (this.countries.length > 0) {
                    // this.draft.courierItem[i].country_code = "11";
                }
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchBranch() {
            try {
                const response = await this.axios.get('/api/branch');
                this.branches = response.data.branches;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchPackageType() {
            try {
                this.goods = [];

                const response = await this.axios.get('/api/goods/quotebook?type=outbound');
                const goods = response.data.goods;

                this.goodsTypes = ['Document', 'Parcel', 'Credit Card', 'Debit Card', 'Statements', 'Debit/Credit Pins' , 'Security Device/Token'];

                if ('Postal' == this.draft.delivery_service_type) {
                    this.goods = goods;
                } else {
                    goods.forEach((good) => {
                        if (this.goodsTypes.includes(good.type)) {
                            this.goods.push(good);
                        }
                    });
                }
                
                if (this.goods.length > 0) {
                    //this.draft.type_of_good = this.goods[3].id;
                    if (this.$route.params.id == undefined) {
                        this.draft.type_of_good = this.goodsDocument
                    }

                    this.goods_type = this.goods[0].type;

                    if (this.$route.params.id == undefined) {
                        this.goods_weight = this.goods[0].weight;
                        this.goods_length = this.goods[0].length;
                        this.goods_width = this.goods[0].width;
                        this.goods_height = this.goods[0].height;
                        this.goods_m3 = ((this.goods[0].height * this.goods[0].length * this.goods[0].width) / 100).toFixed(3);
                        this.goods_is_dg = this.goods[0].is_dg;
                        this.goods_cubic_kg = this.goods[0].cubic_kg;
                    }
                }

                await this.fetchDispatch();
                await this.fetchItemWeight();
                //this.selectRateCard();
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchCarrierServiceType() {
            try {
                const response = await this.axios.get('/api/common/servicetype');
                
                this.servicetypes = response.data.servicetype;

                if (this.auth().user_type == 'user') {
                    const serviceTypes = this.servicetypes.filter((type) => type.service_type == 'Messenger');
                    this.servicetypes = serviceTypes;
                    this.draft.carrier_service_type = this.servicetypes[0].id;
                    return;
                }

                // this.draft.carrier_service_type = "5667aec19a614c56864c5d6f1aa9ff79";
                let messengerType = null
                this.servicetypes.map(function(v){
                    if ('Messenger' == v.service_type) {
                        messengerType = v.id
                        return false 
                    }
                })

                if (this.$route.params.id == undefined) {
                    if (this.servicetypes.length > 0) {
                        this.draft.carrier_service_type = messengerType ? messengerType : this.servicetypes[0].id;
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchItemWeight() {
            if (this.draft.type_of_good) {
                try {
                    const response = await this.axios.get(`/api/goods/${this.draft.type_of_good}`);
                    if (this.$route.params.id == undefined) {
                        this.draft.weight = response.data.goods.weight;
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        },
        async fetchDispatch() {
            //open package details section when type of good is parcel
            console.log(process.env.VUE_APP_TYPE_OF_GOODS_PARCEL);
            if (this.draft.type_of_good == this.goodsParcel) {
                this.parcel_var = true;
                this.package_details_var = true;
            } else {
                this.parcel_var = false;
                this.package_details_var = false;
            }
            if (this.draft.type_of_good) {
                try {
                    const response = await this.axios.get(`/api/goods/${this.draft.type_of_good}`);
                    let i = 0;
                    this.draft.courierItem.forEach(() => {
                        if (this.$route.params.id == undefined) {
                            //if(this.draft.carrier_code== 'DHL')

                            if(response.data.goods) {
                                this.draft.courierItem[i].item_type = response.data.goods.type;
                                this.draft.courierItem[i].item_description = response.data.goods.type;
                                this.draft.courierItem[i].item_weight = response.data.goods.weight;
                                this.draft.courierItem[i].item_length = response.data.goods.length;
                                this.draft.courierItem[i].item_width = response.data.goods.width;
                                this.draft.courierItem[i].item_height = response.data.goods.height;
                                this.draft.courierItem[i].item_m3 = ((response.data.goods.length * response.data.goods.width * response.data.goods.height) / 100).toFixed(3);
                                this.draft.courierItem[i].item_is_dg = response.data.goods.is_dg;
                            }
                            
                            this.draft.courierItem[i].item_cubic_kg = this.draft.courierItem[i].item_m3;
                        }

                        i++;
                    });
                    this.fetchItemWeight();
                }
                catch (error) {
                    console.log(error);
                }
            }
        },
        //remove Corporate type of good from dropdown.
        // fetchDispatch: function (i) {
        //   this.goods.splice(i, 1);
        // },
        viewCompanyAddressBook(type) {
            Object.keys(this.company_address_book).map((element) => {
                this.company_address_book[element] = false;
                this.personal_address_book[element] = false;
            });
            this.company_address_book[type] = true;
            this.personal_address_book[type] = false;
        },
        viewAddressHistory(addressObject) {
            this.showAddressList = addressObject
            this.addressHistoryList = true
        },
        viewPersonalAddressBook(type) {
            Object.keys(this.personal_address_book).map((element) => {
                this.personal_address_book[element] = false;
                this.company_address_book[element] = false;
            });
            this.personal_address_book[type] = true;
            this.company_address_book[type] = false;
        },
        async fetchUserData() {
            this.loading = true;

            try {
                const response = await this.axios.get(`/api/user/data`);
                this.hub_admin = response.data.msg.is_admin == 1;
                this.draft.pickup_name = response.data.msg.name;
                this.draft.pickup_branch = response.data.msg.branch ? response.data.msg.branch.name : "";
                this.draft.pickup_floor = response.data.msg.floor;
                this.draft.pickup_desk = response.data.msg.desk;
                this.draft.pickup_address_1 = response.data.msg.address_1;
                this.draft.pickup_address_2 = response.data.msg.address_2;
                this.draft.pickup_postal_code = response.data.msg.postal_code;
                this.draft.pickup_city = response.data.msg.city;
                this.draft.pickup_suburb = response.data.msg.suburb;
                this.draft.pickup_state = response.data.msg.state;
                this.draft.pickup_country = response.data.msg.country;
                this.draft.pickup_phone = response.data.msg.phone;
                this.draft.pickup_mobile = this.draft.serviceType == this.serviceInternational && this.draft.carrier_code == 'DHL' 
                    ? response.data.msg.phone
                    : response.data.msg.mobile;
                this.draft.pickup_email = response.data.msg.email;
                this.draft.pickup_company = response.data.msg.company.name;
                this.show_pickup_suggestion = false;
                
                if (response.data.msg.division.configs.length > 0) {
                    response.data.msg.division.configs.forEach((config) => {
                        if (config.key == 'is_point_to_point') {
                            this.draft.is_point_to_point = config.value == 'Y' ? 1 : 0;
                        }
                        if (config.key == 'is_default_courier_messenger') {
                            this.draft.is_default_courier_messenger = config.value == 'Y' ? 1 : 0;
                        }
                    });
                }
                // this.loading = false;
            }
            catch (error) {
                console.log(error);
            }
        },
        populatePickupAddress(value) {
            console.log(value);
			if (typeof value.type !== 'undefined') {
				if (value.type != null) {
					this.draft.address_type = value.type;
				}
				if (typeof value.category !== 'undefined') {
					this.draft.pickup_address_category = value.category;
				}
			}
			
            if (!this.draft.pickup_country_code || value.from_address_book) {
                this.draft.pickup_address_1 = value.address_1;
                this.draft.pickup_address_2 = value.address_2;
                this.draft.pickup_postal_code = value.postal_code;
                this.draft.pickup_city = value.city;
                this.draft.pickup_suburb = value.suburb;
                this.draft.pickup_state = value.state;
                this.draft.pickup_country = value.country;
            }

            this.draft.temp_pickup_address_id = value.id;
            this.draft.pickup_name = value.name;
            this.draft.pickup_branch = value.branch_name;
            this.draft.pickup_company = value.company_name;
			this.draft.pickup_floor = value.floor;
			this.draft.pickup_desk = value.desk;
            this.draft.pickup_phone = value.phone;
            this.draft.pickup_mobile = value.mobile;
            // this.draft.pickup_email = value.email;

            if (this.auth().user_type != 'user' && !this.draft.carrier_service_type) {
                if (this.draft.pickup_address_category == 'external' && this.draft.delivery_address_category == 'external') {
                    this.draft.carrier_service_type = this.servicetypes[1].id;
                } else {
                    this.draft.carrier_service_type = this.servicetypes[0].id;
                }
            }
            this.getRate('pickup')
        },
        populateDeliveryAddress(addresses) {
            if (this.draft.entry_type == "single") {
                addresses = [addresses];
            }
            let selectedAddresses = addresses[0];

			if (typeof selectedAddresses.category !== 'undefined') {
				if (selectedAddresses.category != null) {
					this.draft.delivery_address_category = selectedAddresses.category;
				}
				else if (typeof selectedAddresses.category !== 'undefined') {
					this.draft.delivery_address_category = selectedAddresses.category;
				}
			}

            this.draft.delivery_name = selectedAddresses.name;
            this.draft.delivery_company = selectedAddresses.company_name;
            this.draft.delivery_branch = selectedAddresses.branch_name;
            this.draft.delivery_department = selectedAddresses.department
                ? selectedAddresses.department.department_name
                : "";

            if (!this.draft.delivery_country_code || selectedAddresses.from_address_book) {
                this.draft.delivery_floor = selectedAddresses.floor;
                this.draft.delivery_desk = selectedAddresses.desk;
                this.draft.delivery_address_1 = selectedAddresses.address_1;
                this.draft.delivery_address_2 = selectedAddresses.address_2;
                this.draft.delivery_postal_code = selectedAddresses.postal_code;
                this.draft.delivery_city = selectedAddresses.city;
                this.draft.delivery_suburb = selectedAddresses.suburb;
                this.draft.delivery_state = selectedAddresses.state;
                this.draft.delivery_country = selectedAddresses.country;
                this.draft.delivery_phone = selectedAddresses.phone;
                this.draft.delivery_mobile = selectedAddresses.mobile;
                this.draft.delivery_email = selectedAddresses.email;
                this.draft.address_book_id = selectedAddresses.id;

                let countries = localStorage.getItem('countries');
                countries = JSON.parse(countries);

                let selectedCountry = countries.find((country) => {
                    return country.name == selectedAddresses.country;
                });
                this.draft.delivery_country_code = selectedCountry.code;

                this.mapDeliveryAddresses(addresses);
                this.delivery_addresses_count = this.draft.delivery_address.length;
                this.show_destination_suggestion = false;
            }

            if (this.auth().user_type != 'user' && !this.draft.carrier_service_type) {
                if (this.draft.pickup_address_category == 'external' && this.draft.delivery_address_category == 'external') {
                    this.draft.carrier_service_type = this.servicetypes[1].id;
                } else {
                    this.draft.carrier_service_type = this.servicetypes[0].id;
                }
            }

            this.getRate('delivery')
        },
        mapDeliveryAddresses(addresses, selected_from_address_book = true) {
            let i = 0;
            this.draft.delivery_address = [];
            addresses.forEach((address) => {
                this.draft.delivery_address[i] = {
                    delivery_name: address.name,
                    delivery_company: address.company_name,
                    delivery_branch: address.branch_name,
                    delivery_department: address.department
                        ? address.department.department_name
                        : "",
					delivery_floor: address.floor,
					delivery_desk: address.desk,
                    delivery_address_1: address.address_1,
                    delivery_address_2: address.address_2,
                    delivery_postal_code: address.postal_code,
                    delivery_city: address.city,
                    delivery_suburb: address.suburb,
                    delivery_state: address.state,
                    delivery_country: address.country,
                    delivery_phone: address.phone,
                    delivery_mobile: address.mobile,
                    delivery_email: address.email,
                    address_book_id: selected_from_address_book
                        ? address.id
                        : address.address_book_id
                        ? address.address_book_id
                        : address.id,
                };
                i++;
            });
        },
        addressSelected(selectedAddresses) {
            console.log(selectedAddresses); // You will get the address here
        },
        async fetchAddresses() {
            try {
                const response = await this.axios.post('api/address/list');
                this.address_list = response.data.msg;
            }
            catch (error) {
                console.log(error);
            }
        },
        closeModal(value) {
            this.company_address_book[value.type] = false;
            this.personal_address_book[value.type] = false;
        },
        populateManagerName(manager) {
            this.draft.manager_name = manager;
            if (
                this.draft.manager_name != "" &&
                this.threshold_identifier == 1
            ) {
                this.submit();
            } else if (
                this.draft.manager_name != "" &&
                this.threshold_identifier == 2
            ) {
                this.dispatchsubmit();
            } else {
                this.toast.error("Approval Reference Required for Submission ");
            }
        },
        closeModalApprovalReference() {
            this.show_approval_reference = false;
        },
        populatePickupAddressApi(pickupaddress) {
			this.draft.pickup_floor = pickupaddress.pickup_floor;
			this.draft.pickup_desk = pickupaddress.pickup_desk;
            this.draft.pickup_address_1 = pickupaddress.pickup_address_1;
            this.draft.pickup_address_2 = pickupaddress.pickup_address_2;
            this.draft.pickup_suburb = pickupaddress.pickup_suburb;
            this.draft.pickup_city = pickupaddress.pickup_city;
            this.draft.pickup_state = pickupaddress.pickup_state;
            this.draft.pickup_postal_code = pickupaddress.pickup_postal_code;
            this.draft.pickup_country = pickupaddress.pickup_country;
            this.draft.pickup_country_code = pickupaddress.pickup_country_code;
			this.draft.pickup_address_category = 'personal';

            this.getRate('pickup')
        },
        populateGlCodeApi(glCode) {
            if (glCode !== undefined) {
                this.draft.gl_code = glCode;
                this.disable_gl_code = true;
                this.gl_code_placeholder = 'GL Code';
                this.draft.custom_code = 'Gl Code';
                $("#radio_pal"). prop('checked', false);
                $("#radio_other"). prop('checked', false);
            }
        },
        //send details to getRateApi
        findCouriers() {

            if (this.draft.carrier_service_type == this.serviceInternational) {
                if (this.draft.type_of_good == this.goodsDocument && this.draft.weight > 0.75) {
                    this.toast.error(
                        "The package is too heavy to be sent as a Document. Please select the Parcel option.",
                    );
                    return;
                }
                if (this.draft.type_of_good == this.goodsParcel && this.draft.weight > 70) {
                    this.toast.error(
                        "The package weight exceeds maximum of 70kg. Please contact support team.",
                    );
                }
            }

            this.courier_list = false;
            this.error_class = false;
            this.findcourier_btn_edit = true;
            this.courier = {
                requester_name: this.draft.requester_name,
                branch_id: this.draft.branch_id,
                request_date: this.draft.request_date,
                type_of_good: this.draft.type_of_good,
                mode_of_dispatch_name: "Courier",
                mode_of_dispatch: "b1e07d18918a433096936e76cdba63e4",
                carrier_service_type: this.draft.carrier_service_type,
                weight: this.draft.weight.toString(),
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_address_1: this.draft.delivery_address_1,
                pickup_address_1: this.draft.pickup_address_1,
                pickup_city: this.draft.pickup_city,
                pickup_postal_code: this.draft.pickup_postal_code,
                pickup_state: this.draft.pickup_state,
                pickup_country: this.draft.pickup_country,
                pickup_country_code: this.draft.pickup_country_code,
                priority: this.draft.priority,
                pickup_date: this.draft.pickup_date,
                pickup_time: this.draft.pickup_time,
            };

            // this.$refs.observer.validate();
            this.findcourier_btn.disabled = true;
            this.findcourier_btn.length = false;
            this.findcourier_btn.value = "PLEASE WAIT..";

            // this.toast.loading('Finding the Best Couriers for you. Please Wait...');

            this.axios
                .post("/api/courier/findCouriers", this.courier)
                .then((response) => {
                    this.toast.success(response.data.msg);
                    this.draft.mode_of_dispatch = response.data.couriers[0].mode_of_dispatch;
                    this.ratecouriers = response.data.couriers.map(
                        (element) => {
                            element.is_checked = false;

                            // element.cost = element.markup_margin_type == 'per' ? element.rate +((element.markup_margin*1)/100) : element.rate +(element.markup_margin*1);

                            // if(element.rate)
                            //  {
                            //   element.cost = element.markup_margin_type == 'per' ? element.rate +((element.markup_margin*1)/100) : element.rate +(element.markup_margin*1);
                            //   }
                            //   else
                            //   {
                            //     element.cost = 0;
                            //   }
                            if (!element.rate) element.rate = 0;
                            // element.cost = (element.markup_margin_type == 'per' ? element.rate +(((element.markup_margin*1)*element.rate)/100) : element.rate +(element.markup_margin*1)).toFixed(3);
                            element.cost = element.rate.toFixed(2);

                            if (element.service_code == "DBL") {
                                element.courier_rate = 0;
                                element.cost = (element.markup_margin_type ==
                                "per"
                                    ? element.rate + (element.markup_margin * 1 * element.rate) / 100
                                    : element.rate + element.markup_margin * 1
                                ).toFixed(2);
                            }

                            if (element.transit_type == "country") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_country.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_country.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "state") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_state.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_state.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "city") {
                                element.transit_time = element.transit_days > 0 
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_city.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_city.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "postcodes") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_postal_code.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_postal_code.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "suburbs") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_suburb.toUpperCase() &&
                                        element.to_destination.toUpperCase() == this.draft.delivery_suburb.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else {
                                element.transit_time = element.default_transit_days;
                            }

                            return element;
                        }
                    );

                    if (this.ratecouriers.length > 0) {
                        this.courier_list = true;
                        this.ratecouriers[0].is_checked = true;
                        this.findcourier_btn.disabled = false;
                        this.findcourier_btn.value = "FIND COURIERS";
                        this.SelectCourier(this.ratecouriers[0]);
                    } else {
                        this.findcourier_btn.length = true;
                        // this.toast.error('No couriers found.');
                    }

                    this.findcourier_btn.disabled = false;
                    this.findcourier_btn.value = "FIND COURIERS";
                    // this.updateWeight();

                    if (response.data.couriers[0].window_schedule) {
                        this.window_pickup_from_time = response.data.couriers[0].window_schedule.from;
                        this.window_pickup_to_time = response.data.couriers[0].window_schedule.to;
                    }                    
                })
                .catch(() => {
                    this.findcourier_btn.disabled = false;
                    this.findcourier_btn.length = true;
                    this.findcourier_btn.value = "FIND COURIERS";
                    // this.toast.error('No couriers found.');
                });
        },
        SelectCourier(ratecourier) {
            
            this.draft.provider_id = ratecourier.provider_id;
            // this.draft.cost  = ratecourier.markup_margin_type == 'per' ? ratecourier.rate +((ratecourier.markup_margin*1)/100) : ratecourier.rate +(ratecourier.markup_margin*1);
            this.draft.cost = ratecourier.cost;
            this.draft.service_id = ratecourier.service_id;
            this.draft.carrier_code = ratecourier.carrier_code;
            //this.draft.transit_time = ratecourier.transit_days > 0 ? ratecourier.transit_days : ratecourier.default_transit_days;
            this.draft.transit_time = ratecourier.transit_time;
            this.draft.carrier_code = ratecourier.carrier_code;
            // this.addresses[index].is_checked = isChecked;
            this.draft.courier_rate = ratecourier.courier_rate;
            // this.draft.sla = ratecourier.sla;

            if (
                this.draft.carrier_service_type == this.serviceInternational &&
                this.draft.carrier_code == "DHL" &&
                this.draft.type_of_good != this.goodsDocument
            ) {
                let i = 0;
                this.draft.courierItem.forEach(() => {
                    this.draft.courierItem[
                        i
                    ].invoice_no = this.draft.pickup_company;
                    i++;
                });
            }
            // For DHL default, good description as per package type (Document and Package)
            // if(this.draft.carrier_code== 'DHL'){
            if (this.$route.params.id == undefined) {
                let i = 0;
                this.draft.courierItem.forEach(() => {
                    this.draft.courierItem[
                        i
                    ].item_description = this.draft.courierItem[0].item_type;
                    i++;
                });
            }

            // }

            if (this.draft.carrier_code == "DHL") {
                let i = 0;
                this.draft.courierItem.forEach(() => {
                    this.draft.courierItem[i].carrier_code = this.draft.carrier_code;
                    this.draft.courierItem[i].item_height = '';
                    this.draft.courierItem[i].item_width = '';
                    this.draft.courierItem[i].item_length = '';
                    i++;
                });

                if (this.draft.type_of_good == this.goodsParcel) {
                    console.log('test test');
                    this.draft.item_length = '';
                    this.draft.item_width = '';
                    this.draft.item_height = '';
                }
            }

            if (ratecourier.window_schedule) {
                this.window_pickup_from_time = ratecourier.window_schedule.from;
                this.window_pickup_to_time = ratecourier.window_schedule.to;
            }

            const mandatoryDimensionsCouriers = ['STC', 'DHL'];
            this.package_details_var = mandatoryDimensionsCouriers.includes(ratecourier.carrier_code);

            this.draft.pickup_mobile = this.draft.carrier_service_type == this.serviceInternational && this.draft.carrier_code == 'DHL' 
                ? this.draft.pickup_phone
                : this.draft.pickup_mobile;

            this.draft.delivery_mobile = this.draft.carrier_service_type == this.serviceInternational && this.draft.carrier_code == 'DHL' 
                ? this.draft.delivery_phone
                : this.draft.delivery_mobile;
        },
        async thresholdchecksubmit() {

            this.loading = true;
            this.threshold_identifier = 1;
            this.final_weight_flag = false;
            this.count_weight = 0;
            this.count_qty = 0;
            this.draft.courierItem.forEach((value) => {
                this.count_weight += parseFloat(value.item_weight);
            });

            // temporary fix
            if (this.draft.courierItem.length === 2) {
                this.count_weight = this.draft.courierItem[0].item_weight;
            }

            if (this.count_weight == this.draft.weight) {
                this.final_weight_flag = true;
            }

            if (this.final_weight_flag == true) {
                if (this.draft.service_id == null || this.draft.provider_id == null) {
                    //draft submit without findcourier button click
                    this.toast.error("Please Press 'Find Couriers' and Choose a Carrier to Proceed");
                } else {
                    if (!this.validatePickupDateTime()) {
                        this.toast.error(
                            !this.today_pickup 
                            ? `Pickup Date and Time must be in future and inside window time of ${this.draft.carrier_code == 'STC' ? '7:00am to 4:00pm.' : '8:00am to 5:00pm.'}` 
                            : `Pickup date must be today and time must be in inside window time of ${this.draft.carrier_code == 'STC' ? '7:00am to 4:00pm.' : '8:00am to 5:00pm.'}`
                        );
                        return;
                    }

                    if (this.draft.carrier_code == 'DHL' && !this.validateInternationPickupDateTime()) {
                        this.toast.error(
                            "The Pickup Shipment Date cannot be in the past or more than 10 days in future.",
                        );
                        return;
                    }

                    if (this.draft.carrier_code == 'DHL' && this.draft.type_of_good == this.goodsDocument && this.draft.weight > 0.75) {
                        this.toast.error(
                            "The package is too heavy to be sent as a Document. Please select the Parcel option.",
                        );
                        return;
                    }

                    if (this.draft.carrier_code == 'DHL' && this.draft.type_of_good == this.goodsParcel) {

                        if (this.draft.weight > 70) {
                            this.toast.error(
                                "The package weight exceeds maximum of 70kg. Please contact support team.",
                            );
                            return;
                        }

                        let hasDimensionError = false;
                        this.draft.courierItem.forEach((obj) => {
                            if (typeof obj !== 'undefined' && (parseFloat(obj.item_length) > 120.00 || parseFloat(obj.item_width) > 120.00 || parseFloat(obj.item_height > 120.00))) {
                                hasDimensionError = true;
                            }
                        });

                        if (hasDimensionError) {
                            this.toast.error(
                                "The package Length, Width or Height exceeds maximum of 120cm. Please contact support team.",
                            );
                            return;
                        }
                    }
					
					if (this.draft.matter_number_id === '') {
						this.toast.error("Please select matter number!");
                        return;
					}

                    try {

                        const response = await this.axios.get(`/api/budget/${this.draft.provider_id}/${this.draft.service_id}`);
                        if (response.data.data) {

                            if (this.draft.cost > response.data.data.approval_required_threshold * 1) {
                                // code for popup
                                this.show_approval_reference = true;
                            } else {
                                if (this.findcourier_btn_edit == true) {
                                    //when click on edit button you  must click on the  findcourier button.
                                    await this.submit();
                                } else {
                                    this.toast.error(`Please Press 'Find Couriers' and Choose a Carrier to Proceed`);
                                }
                            }

                        } else {

                            //in case of threshold value not set
                            //Either we can directly submit or we can stop the procees here i am directly submitting

                            if (this.findcourier_btn_edit == true) {
                                if (this.final_weight_flag == true) {
                                    await this.submit();
                                } else {
                                    this.toast.error(
                                        "Please check item weight"
                                    );
                                }
                            } else {
                                this.toast.error(`Please Press 'Find Couriers' and Choose a Carrier to Proceed`);
                            }
                        }

                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            } else {
                this.toast.error("Please check item weight");
            }
        },
        async thresholdcheckdipatchsubmit() {
            this.loading = true;
            this.threshold_identifier = 2;
            this.final_weight_flag = false;
            this.count_weight = 0;

            this.draft.courierItem.forEach((value) => {
                this.count_weight += parseFloat(value.item_weight);
            });

            if (this.count_weight == this.draft.weight) {
                this.final_weight_flag = true;
            }   

            if (this.final_weight_flag == true) {
                if (this.draft.service_id == null || this.draft.provider_id == null) {
                    //draft submit without findcourier button click
                    this.toast.error("Please Press 'Find Couriers' and Choose a Carrier to Proceed");
                } else {

					if (this.draft.matter_number_id === '') {
						this.toast.error("Please select matter number!");
                        return;
					}
                    if (!this.validatePickupDateTime()) {
                        this.toast.error(
                            !this.today_pickup 
                            ? `Pickup Date and Time must be in future and inside window time of ${this.draft.carrier_code == 'STC' ? '7:00am to 4:00pm.' : '8:00am to 5:00pm.'}` 
                            : `Pickup date must be today and time must be in inside window time of ${this.draft.carrier_code == 'STC' ? '7:00am to 4:00pm.' : '8:00am to 5:00pm.'}`
                        );
                        return;
                    }

                    if (this.draft.carrier_code == 'DHL' && !this.validateInternationPickupDateTime()) {
                        this.toast.error(
                            "The Pickup Shipment Date cannot be in the past or more than 10 days in future.",
                        );
                        return;
                    }

                    if (this.draft.carrier_code == 'DHL' && this.draft.type_of_good == this.goodsDocument && parseFloat(this.draft.weight) > 0.75) {
                        this.toast.error(
                            "The package is too heavy to be sent as a Document. Please select the Parcel option.",
                        );
                        return;
                    }

                    if (this.draft.carrier_code == 'DHL' && this.draft.type_of_good == this.goodsParcel) {

                        if (this.draft.weight > 70) {
                            this.toast.error(
                                "The package weight exceeds maximum of 70kg. Please contact support team.",
                            );
                            return;
                        }

                        let hasDimensionError = false;
                        this.draft.courierItem.forEach((obj) => {
                            if (typeof obj !== 'undefined' && (parseFloat(obj.item_length) > 120.00 || parseFloat(obj.item_width) > 120.00 || parseFloat(obj.item_height > 120.00))) {
                                hasDimensionError = true;
                            }
                        });

                        if (hasDimensionError) {
                            this.toast.error(
                                "The package Length, Width or Height exceeds maximum of 120cm. Please contact support team.",
                            );
                            return;
                        }
                    }
					
					if (this.draft.matter_number_id === '') {
						this.toast.error("Please select matter number!");
                        return;
					}

                    /**
                     * Temporary validations for international (DHL) booking. It can be improved.
                     * Also, these are already validated thru backend/api.
                     */
                    if (this.draft.carrier_code == 'DHL') {

                        if (this.draft.pickup_address_2 == '' || this.draft.pickup_address_2 == null) {
                            this.toast.error(`Please provide pickup address 2.`);
                            return;
                        }
                        else if (this.draft.pickup_mobile == '' || this.draft.pickup_mobile == null) {
                            this.toast.error(`Please provide pickup mobile number.`);
                            return;
                        }
                        if (this.draft.delivery_email == '' || this.draft.delivery_email == null) {
                            this.toast.error(`Please provide delivery email address.`);
                            return;
                        }
                        else if (this.draft.delivery_address_2 == '' || this.draft.delivery_address_2 == null) {
                            this.toast.error(`Please provide delivery address 2.`);
                            return;
                        }
                        else if (this.draft.delivery_phone == '' || this.draft.delivery_phone == null) {
                            this.toast.error(`Please provide delivery phone number.`);
                            return;
                        }
                        else if (this.draft.delivery_mobile == '' || this.draft.delivery_mobile == null) {
                            this.toast.error(`Please provide delivery mobile number.`);
                            return;
                        }
                        else if (this.draft.type_of_good == this.goodsParcel) {
                            if (this.draft.courierItem[0].commercial_value == '' || this.draft.courierItem[0].commercial_value == null) {
                                this.toast.error(`Please provide a commercial value.`);
                                return;
                            }
                            else if (this.draft.courierItem[0].item_length == '' || this.draft.courierItem[0].item_length == null) {
                                this.toast.error(`Please provide an item length.`);
                                return;
                            }
                            else if (this.draft.courierItem[0].item_width == '' || this.draft.courierItem[0].item_width == null) {
                                this.toast.error(`Please provide an item width.`);
                                return;
                            }
                            else if (this.draft.courierItem[0].item_height == '' || this.draft.courierItem[0].item_height == null) {
                                this.toast.error(`Please provide an item height.`);
                                return;
                            }
                        }
                    }

                    try {
                        const response = await this.axios.get(`/api/budget/${this.draft.provider_id}/${this.draft.service_id}`);
                        if (response.data.data) {
                            if (this.draft.cost > response.data.data.approval_required_threshold * 1) {
                                // code for popup
                                this.show_approval_reference = true;
                            } else {
                                if (this.findcourier_btn_edit == true) {
                                    this.dispatchsubmit();
                                } else {
                                    this.toast.error(`Please Press 'Find Couriers' and Choose a Carrier to Proceed`);
                                }
                            }
                        } else {
                            //in case of threshold value not set
                            //Either we can directly submit or we can stop the procees here i am directly submitting

                            if (this.findcourier_btn_edit == true) {
                                this.dispatchsubmit();
                            } else {
                                this.toast.error(
                                    "Please Press 'Find Couriers' and Choose a Carrier to Proceed"
                                );
                            }
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
            } else {
                this.toast.error("Please check item weight");
            }
        },
        async submit() {

            this.draft.delivery_address[0] = {
                delivery_name: this.draft.delivery_name,
                delivery_company: this.draft.delivery_company,
                delivery_branch: this.draft.delivery_branch,
                delivery_department: this.draft.delivery_department,
				delivery_floor: this.draft.delivery_floor,
				delivery_desk: this.draft.delivery_desk,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_address_2: this.draft.delivery_address_2,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_phone: this.draft.delivery_phone,
                delivery_mobile: this.draft.delivery_mobile,
                delivery_email: this.draft.delivery_email,
                address_book_id: this.draft.address_book_id ? this.draft.address_book_id : "htfjfgjhgjg",
            };

            this.$refs.observer.validate();
            this.draft.is_draft = 0;

            this.submit_btn.disabled = true;
            this.submit_btn.value = "Submitting, Please wait";

            await this.saveToAddressBook();

            if (this.$route.params.id || this.draft.id) {

                const id = this.draft.id ? this.draft.id : this.$route.params.id;

                try {
                    const response = await this.axios.post(`/api/draft/quotebook/${id}`, this.draft);

                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(response.data.draft.id);
                        this.toast.error(response.data.msg);
                        return;
                    }

                    this.toast.success(response.data.msg);
                    //this.viewconsignmentpopup_booking_id = response.data.id;
                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";
                }

            } else {

                try {
                    const response = await this.axios.post(`/api/draft/quotebook`, this.draft);

                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(response.data.draft.id);
                        this.toast.error(response.data.msg);
                        return;
                    }

                    //this.viewconsignmentpopup_booking_id = response.data.id;
                    this.toast.success(response.data.msg);
                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";
                }
            }
        },
        async savePostalQuote() {

            if (this.save_postal_btn.disable) {
                this.toast.success("SAVING...");
                return;
            }

            this.save_postal_btn.disable = true
            this.save_postal_btn.value = 'SAVING...'

            this.draft.is_draft = 0;
            this.draft.booking_type = 'Postal'

            this.axios
                .post("/api/draft/quotebook", this.draft)
                .then((response) => {

                    this.toast.success(response.data.msg);
                    this.save_postal_btn = {
                        disabled: false,
                        value: "Save",
                    }
                    this.$router.push("/pages/ViewCourierPending");
                })
                .catch((error) => {
                    console.log(error);
                    this.save_postal_btn = {
                        disabled: false,
                        value: "Save",
                    }
                });
        },
        async dispatchsubmit() {
            this.draft.delivery_address[0] = {
                delivery_name: this.draft.delivery_name,
                delivery_company: this.draft.delivery_company,
                delivery_branch: this.draft.delivery_branch,
                delivery_department: this.draft.delivery_department,
				delivery_floor: this.draft.delivery_floor,
				delivery_desk: this.draft.delivery_desk,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_address_2: this.draft.delivery_address_2,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_phone: this.draft.delivery_phone,
                delivery_mobile: this.draft.delivery_mobile,
                delivery_email: this.draft.delivery_email,
                address_book_id: this.draft.address_book_id
                    ? this.draft.address_book_id
                    : "htfjfgjhgjg",
            };

            this.$refs.observer.validate();
            this.draft.is_draft = 0;
            this.dispatchsubmit_btn.disabled = true;
            this.dispatchsubmit_btn.value = "Submitting, Please wait";

            await this.saveToAddressBook();

            if (this.$route.params.id || this.draft.id) {

                const id = this.draft.id ? this.draft.id : this.$route.params.id;

                try {
                    const response = await this.axios.post(`/api/draft/quotebook/dispatch/${id}`, this.draft);
                    
                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(id);
                        this.toast.error(response.data.msg);
                        if (response.data.draft.id) {
                            this.$router.push("/pages/ViewCourierPending");
                        }
                        return;
                    }
                    
                    this.toast.success(response.data.msg);
                    //this.viewconsignmentpopup_booking_id = response.data.id;
                    // this.$router.push("/pages/ViewCourierPending");   
                }
                catch (error) {
                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";
                }
                
            } else {

                try {
                    const response = await this.axios.post(`/api/draft/quotebook/dispatch`, this.draft);
                    
                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(response.data.draft.id);
                        this.toast.error(response.data.msg);
                        if (response.data.draft.id) {
                            this.$router.push("/pages/ViewCourierPending");
                        }
                        return;
                    }

                    this.toast.success(response.data.msg);
                    //this.viewconsignmentpopup_booking_id = response.data.id;
                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";
                }

            }
        },
        draftsubmit() {
            this.draft.delivery_address[0] = {
                delivery_name: this.draft.delivery_name,
                delivery_company: this.draft.delivery_company,
                delivery_branch: this.draft.delivery_branch,
                delivery_department: this.draft.delivery_department,
				delivery_floor: this.draft.delivery_floor,
				delivery_desk: this.draft.delivery_desk,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_address_2: this.draft.delivery_address_2,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_phone: this.draft.delivery_phone,
                delivery_mobile: this.draft.delivery_mobile,
                delivery_email: this.draft.delivery_email,
                address_book_id: this.draft.address_book_id
                    ? this.draft.address_book_id
                    : "htfjfgjhgjg",
            };

            this.$refs.observer.validate();
            this.draft.is_draft = 1;

            this.save_btn.disabled = true;
            this.save_btn.value = "SAVING...";

            if (this.$route.params.id) {
                this.axios
                    .post(
                        "/api/draft/quotebook/" + this.$route.params.id,
                        this.draft
                    )
                    .then((response) => {
                        console.log(response);
                        this.$router.push("/pages/Draft");
                        this.toast.success(response.data.msg);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    })
                    .catch((error) => {
                        console.log(error);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    });
            } else {
                this.axios
                    .post("/api/draft/quotebook", this.draft)
                    .then((response) => {
                        console.log(response);
                        this.$router.push("/pages/Draft");
                        this.toast.success(response.data.msg);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    })
                    .catch((error) => {
                        console.log(error);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    });
            }
        },
        enableEditMode() {
            console.log(this.draft.type_of_good);
            this.enable_edit_var = true;
            this.findcourier_btn_edit = false;
        },
        closeConsignment() {
            this.viewconsignmentpopup_booking_id = "";
            this.$router.push("/pages/ViewCourierPending");
            this.courier = this.courier.map((element) => {
                element.show_view_popup = false;
                return element;
            });
        },
        validatePickupDateTime() {

            this.window_pickup_to_time = this.hub_admin && this.draft.carrier_code != 'STC' ? '19:00:00' : this.window_pickup_to_time;

            const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
            const now = moment(moment().format(dateTimeFormat), dateTimeFormat);
            const date = moment(`${this.draft.pickup_date} ${this.draft.pickup_time}:00`, dateTimeFormat);
            const start = moment(`${this.draft.pickup_date} ${this.window_pickup_from_time}`, dateTimeFormat);
            const end = moment(`${this.draft.pickup_date} ${this.window_pickup_to_time}`, dateTimeFormat);
            if (!this.today_pickup) {
				return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(now.format('YYYY-MM-DD')) && date.isBetween(start, end);
			} else {
				return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(now.format('YYYY-MM-DD')) && date.isBetween(start, end);
			}
        },
        validateInternationPickupDateTime() {
            const dateFormat = 'YYYY-MM-DD';
            const now = moment(moment().format(dateFormat), dateFormat);
            const date = moment(this.draft.pickup_date, dateFormat);

            return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(now.format('YYYY-MM-DD')) && date.isSameOrBefore(now.add(10, 'd'));
        },
        checkDateIfToday(value) {
            const dateTimeFormat = 'YYYY-MM-DD';
            const now = moment(moment().format(dateTimeFormat), dateTimeFormat);
            const date = moment(value[0], 'YYYY-MM-DD').format('YYYY-MM-DD');
            const isDateBefore = moment(date, 'YYYY-MM-DD').isBefore(now.format('YYYY-MM-DD'));
            const isDateSameOrBefore = moment(date, 'YYYY-MM-DD').isSameOrBefore(now.format('YYYY-MM-DD'));
            const isMoreThan10DaysInFuture = moment(date, 'YYYY-MM-DD').isAfter(now.add(10, 'd'));

            if (this.draft.carrier_service_type == this.serviceInternational) {
                if (this.draft.carrier_code == "DHL") {
                    if (isMoreThan10DaysInFuture) {
                        this.toast.error("The Pickup Shipment Date cannot be more than 10 days in future.");
                        return;
                    }
                    if (isDateSameOrBefore) {
                        this.toast.error("The Pickup Shipment Date cannot be in the past or today.");
                        return;
                    }
                }
            }
            else {
                if (isDateBefore) {
                    this.toast.error("The Pickup Shipment Date cannot be in the past.");
                    return;
                }
            }

            this.today_pickup = moment(date, 'YYYY-MM-DD').isSame(now.format('YYYY-MM-DD'));
        },
		toggleTodayPickup(event) {
			this.today_pickup = event.target.checked;

            if (this.$route.params.id != undefined) {
                if (this.today_pickup) {
                    this.draft.pickup_date = moment().format("YYYY-MM-DD");
                    this.draft.pickup_time = moment().format("HH:mm:ss");
                } else {
                    this.draft.pickup_date = this.temp_pickup_date;
                    this.draft.pickup_time = this.temp_pickup_time;
                }
            }
		},
        async saveToAddressBook() {
            try {
                if (this.save_address.internal || this.save_address.external) {
                    await this.axios.post(
                        "/api/address/createFromDeliveryAddress",
                        {
                            category: this.save_address.external
                                ? "external"
                                : this.save_address.internal
                                ? "internal"
                                : "",
                            name: this.draft.delivery_name,
                            company_name: this.draft.delivery_company,
                            branch_name: this.draft.delivery_branch,
                            department_id: this.draft.delivery_department,
                            cost_center_id: "",
                            delivery_location: "",
                            address_1: this.draft.delivery_address_1,
                            address_2: this.draft.delivery_address_2,
                            postal_code: this.draft.delivery_postal_code,
                            country: this.draft.delivery_country,
                            city: this.draft.delivery_city,
                            suburb: this.draft.delivery_suburb,
                            state: this.draft.delivery_state,
                            phone: this.draft.delivery_phone,
                            email: this.draft.delivery_email,
                            preferred_address: "0",
                            preferred: {
                                address_1: "",
                                address_2: "",
                                postal_code: "",
                                country: "",
                                city: "",
                                suburb: "",
                                state: "",
                            },
                        }
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
		async fetchMatterNumber() {
			try {
                let localMatterNumbers = [];
                localMatterNumbers = localStorage.getItem('matter_numbers');

                if (localMatterNumbers) {
                    localMatterNumbers = JSON.parse(localMatterNumbers);
                    if (localMatterNumbers.length > 0) {
                        this.matter_numbers = localMatterNumbers;
                        this.draft.matter_number_id = 0;
                        return;
                    }
                }

				const matterNumberResponse$ = await this.axios.get('/api/mater-numbers');
				if (matterNumberResponse$) {
					this.matter_numbers = [];
					const matterNumbers = matterNumberResponse$.data.matter_number;
					this.matter_numbers.push({
						id: 0,
						label: 'No Matter Number',
					});
					matterNumbers.forEach((matterNumber) => {
						this.matter_numbers.push({
							id: matterNumber.id,
							label: matterNumber.matter_number,
						});
					});

                    localStorage.setItem('matter_numbers', JSON.stringify(this.matter_numbers));

                    this.draft.matter_number_id = 0;
				}
			}
			catch (err) {
				console.log(err);
			}
		},
		async fetchGlCode() {
			try {
				const glCodeResponse$ = await this.axios.get('/api/glcode');
				if (glCodeResponse$) {
					this.draft.gl_code = glCodeResponse$.data.gl_code;
				}
			}
			catch (err) {
				console.log(err);
			}
		},
		setSelectedMatterNumber(value) {
			// this.draft.gl_code = '';
			// if (value.id === 0) {
			// 	this.draft.gl_code = auth().gl_code;
			// }
            
			this.draft.matter_number_id = '';
			if (typeof value !== 'undefined' && value != null) {
				this.draft.matter_number_id = typeof value.id !== 'undefined' ? value.id : value;
			}
		},
        onServiceTypeChange(event) {
            this.setDeliveryServiceType(event.target.value);
        },
        setDeliveryServiceType(serviceTypeId) {
            const serviceTypeName = this.servicetypes.find((serviceType) => serviceType.id == serviceTypeId);
            this.draft.delivery_service_type = serviceTypeName.service_type;
            this.fetchPackageType();
            if ('Postal' == serviceTypeName.service_type) {
                this.draft.priority = 'Contract'
                this.draft.carrier_service_type = serviceTypeName.id
                return;
            }
            this.draft.priority = 'Standard'
        },
        selectCustomCode(customCode) {
            this.disable_gl_code = false;
            this.draft.gl_code = null;
            this.gl_code_placeholder = customCode;
            this.draft.custom_code = customCode;
        },
        setSelectedCountry(value) {
            this.draft.delivery_country = '';
            this.draft.delivery_country_code = '';
			if (typeof value !== 'undefined' && value != null) {
				this.draft.delivery_country = typeof value.label !== 'undefined' ? value.label : this.countries.find((country) => country.code == value).name;
				this.draft.delivery_country_code = typeof value.id !== 'undefined' ? value.id : this.countries.find((country) => country.code == value).code;
			}
        },
        async getCountries() {
            try {

                this.lcountries.push({
                    id: '',
                    label: 'Select Country',
                });

                let countries = await this.setCountries();
                countries = countries.filter((item, index) => {
                    return countries.indexOf(item) == index;
                });

                for (let i=0; i<countries.length; i++) {
                    this.lcountries.push({
                        id: countries[i].code,
                        label: countries[i].name,
                    });
                }
            }
            catch (e) {
                console.log(e);
            }
        },
        async populateDraft(id) {

            try {
                const response = await this.axios.get(`/api/draft/${id}`);

                setTimeout(() => {
                    this.draft.matter_number_id = response.data.draft.matter_number ? response.data.draft.matter_number.id : 0;
                    this.draft.matter_number = response.data.draft.matter_number ? response.data.draft.matter_number.matter_number : '';
                    this.setDeliveryServiceType(response.data.draft.carrier_service_type);

                    setTimeout(() => {
                        this.findcourier_btn_edit = true;
                        this.delivery_addresses_count = response.data.count;
                        this.draft.entry_type = response.data.draft.entry_type;
                        this.draft.request_date = response.data.draft.request_date;
                        this.draft.requester_name =response.data.draft.requester_name;
                        this.draft.branch_id = response.data.draft.branch_id;
                        this.draft.type_of_good = response.data.draft.type_of_good;
                        this.draft.carrier_service_type = response.data.draft.carrier_service_type;
                        this.draft.weight = response.data.draft.weight;
                        this.draft.mode_of_dispatch = response.data.draft.mode_of_dispatch;
                        this.draft.carrier_code = response.data.draft.carrier_code;
                        this.draft.service_id = response.data.draft.service_id;
                        this.draft.pickup_name = response.data.draft.pickup_name;
                        this.draft.pickup_company = response.data.draft.pickup_company;
                        this.draft.pickup_branch = response.data.draft.pickup_branch;
                        this.draft.pickup_floor = response.data.draft.pickup_floor;
                        this.draft.pickup_desk = response.data.draft.pickup_desk;
                        this.draft.pickup_address_1 = response.data.draft.pickup_address_1;
                        this.draft.pickup_address_2 = response.data.draft.pickup_address_2;
                        this.draft.pickup_city = response.data.draft.pickup_city;
                        this.draft.pickup_state = response.data.draft.pickup_state;
                        this.draft.pickup_suburb = response.data.draft.pickup_suburb;
                        this.draft.pickup_postal_code = response.data.draft.pickup_postal_code;
                        this.draft.pickup_country = response.data.draft.pickup_country;
                        this.draft.pickup_country_code = response.data.draft.pickup_country_code;
                        this.draft.pickup_phone = response.data.draft.pickup_phone;
                        this.draft.pickup_email = response.data.draft.pickup_email;
                        this.draft.delivery_name = response.data.draft.delivery_name;
                        this.draft.delivery_branch = response.data.draft.delivery_branch;
                        this.draft.delivery_company = response.data.draft.delivery_company;
                        this.draft.delivery_floor = response.data.draft.delivery_floor;
                        this.draft.delivery_desk = response.data.draft.delivery_desk;
                        this.draft.delivery_address_1 = response.data.draft.delivery_address_1;
                        this.draft.delivery_address_2 = response.data.draft.delivery_address_2;
                        this.draft.delivery_city = response.data.draft.delivery_city;
                        this.draft.delivery_state = response.data.draft.delivery_state;
                        this.draft.delivery_suburb = response.data.draft.delivery_suburb;
                        this.draft.delivery_postal_code = response.data.draft.delivery_postal_code;
                        this.draft.delivery_country = response.data.draft.delivery_country;
                        this.draft.delivery_country_code = response.data.draft.delivery_country_code;
                        this.draft.delivery_phone = response.data.draft.delivery_phone;
                        this.draft.delivery_email = response.data.draft.delivery_email;
                        this.draft.reference_no = response.data.draft.reference_no;
                        this.draft.goods_description = response.data.draft.goods_description;
                        this.draft.pickup_instructions = response.data.draft.pickup_instructions;
                        this.draft.remarks = response.data.draft.remarks;
                        this.draft.office_close_time = response.data.draft.office_close_time;
                        this.draft.pickup_date = moment(response.data.draft.pickup_date, "DD.MM.YYYY").format("YYYY-MM-DD");
                        this.draft.pickup_time = response.data.draft.pickup_time;
                        this.temp_pickup_date = moment(response.data.draft.pickup_date, "DD.MM.YYYY").format("YYYY-MM-DD");
                        this.temp_pickup_time = response.data.draft.pickup_time;
                        this.draft.id = id;      
                    }, 500);
                }, 1000);

                // Multiple CourierItem
                let i = 0;
                this.draft.courierItem = [];

                response.data.courierItem.forEach(() => {
                    this.draft.courierItem[i] = {
                        item_reference: response.data.courierItem[i].item_reference,
                        item_type: response.data.courierItem[i].item_type,
                        item_description: response.data.courierItem[i].item_description,
                        item_charge_unit: response.data.courierItem[i].item_charge_unit,
                        item_qty: response.data.courierItem[i].item_qty,
                        item_weight: response.data.courierItem[i].item_weight,
                        item_length: response.data.courierItem[i].item_length,
                        item_width: response.data.courierItem[i].item_width,
                        item_height: response.data.courierItem[i].item_height,
                        item_m3: response.data.courierItem[i].item_m3,
                        item_cubic_kg: response.data.courierItem[i].item_cubic_kg,
                        item_is_dg: response.data.courierItem[i].item_is_dg,
                        product_code: response.data.courierItem[i].product_code,
                        export_reason_type: response.data.courierItem[i].export_reason_type,
                        country_code: response.data.courierItem[i].country_code,
                        commercial_value: response.data.courierItem[i].commercial_value,
                        insured_value: response.data.courierItem[i].insured_value,
                        currency: response.data.courierItem[i].currency,
                        invoice_no: response.data.courierItem[i].invoice_no,
                        invoice_date: response.data.courierItem[i].invoice_date,
                        booking_id: response.data.courierItem[i].booking_id,
                        id: response.data.courierItem[i].id,
                    };

                    if (response.data.courierItem[i].item_type == "Parcel") {
                        this.parcel_var = true;
                    }
                    if (response.data.courierItem[i].item_type == "Document") {
                        this.package_details_var = true;
                    }
                    i++;
                });

                //carrier list shown on edit
                this.ratecouriers = [];
                if (response.data.draft.provider.id != null) {
                    this.ratecouriers[0] = {
                        is_carrier_preference: response.data.draft.provider.is_carrier_preference,
                        logo: response.data.draft.provider.logo,
                        name: response.data.draft.provider.name,
                        is_checked: true,
                        service_name: response.data.draft.delivery_service,
                        transit_time: response.data.draft.transit_time,
                        is_dg: response.data.draft.provider.is_dg,
                        cost: response.data.draft.cost,
                        description: response.data.draft.provider.description,
                        sell_rate_margin: response.data.draft.provider.sell_rate_margin,
                        sla: response.data.draft.provider.sla,
                        provider_id: response.data.draft.provider.id,
                        service_id: response.data.draft.service_id,
                        carrier_code: response.data.draft.carrier_code,
                        courier_rate: response.data.draft.courier_rate,
                    };
                }

                this.SelectCourier(this.ratecouriers[0]);
                this.customerRefenceNo();

                if (response.data.address_book_id && response.data.address_book_id.length > 0) {
                    this.mapDeliveryAddresses(
                        response.data.address_book_id,
                        false
                    );
                    this.draft.address_book_id = response.data.address_book_id[0].id ? response.data.address_book_id[0].id : "";
                } else {
                    this.draft.address_book_id = "sbdhcbwkds";
                }

                // this.updateWeight();
                
                const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
                const date = moment(this.draft.pickup_date + " " + this.draft.pickup_time);

                if (moment(date.format('YYYY-MM-DD')).isBefore(now.format('YYYY-MM-DD'))) {
                    this.today_pickup = false;
                }
                else if (moment(date.format('YYYY-MM-DD')).isSame(now.format('YYYY-MM-DD'))) {
                    this.today_pickup = true;
                }
                else if (moment(date.format('YYYY-MM-DD')).isAfter(now.format('YYYY-MM-DD'))) {
                    this.today_pickup = false;
                }

                //set category in edit mode
                this.draft.delivery_address_category = response.data.draft.delivery_address_category;
                this.draft.pickup_address_category = response.data.draft.pickup_address_category;

                setTimeout(() => {
                    const courierData = response.data.courier;
                    if (courierData.window_schedule) {
                        this.window_pickup_from_time = courierData.window_schedule.from;
                        this.window_pickup_to_time = courierData.window_schedule.to;
                    }
                }, 1000);

                const countries = JSON.parse(localStorage.getItem('countries'));
                const selectedDeliveryCountry = countries.find((country) => {
                    return country.name == this.draft.delivery_country;
                });
                const selectedPickupCountry = countries.find((country) => {
                    return country.name == this.draft.pickup_country;
                });

                this.draft.delivery_country_code = selectedDeliveryCountry ? selectedDeliveryCountry.code : '';
                this.draft.pickup_country_code = selectedPickupCountry ? selectedPickupCountry.code : '';
                
                
            }
            catch (error) {
                console.log(error);
            }

            if (this.enable_edit_var) {
                setTimeout(() => {
                    this.package_details_var = false;
                }, 1000);
            }
        },
    },
    watch: {
        'draft.weight': function(newVal, oldVal) {
            this.updateWeight();
        }
        // 'draft.mode_of_dispatch'(){
        //   this.fetchProviders();
        // }
    },
};
</script>
<style scoped>
.w40 {
    width: 40% !important;
}
.service_details_container {
    padding: 20px;
}

.service_details_container > section {
    padding: 20px 0 0 0;
}

.service_details_container > section h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: inline-block;
}

.title_image {
    display: block;
    margin: auto;
}

h4 {
    font-weight: 600;
    text-align: center;
    padding: 30px 0;
    font-size: 18px;
}

.step_view_wrapper {
    width: 100%;
    height: 140px;
    background: #181818;
    padding: 20px 80px;
    position: relative;
}

.step_view_wrapper .strip {
    width: calc(100% - 160px);
    height: 10px;
    background: #666;
    position: absolute;
    top: 50%;
    line-height: 0;
}

.step_view_wrapper .strip_half {
    height: 10px;
    width: 50%;
    background: #06a5ed;
    display: inline-block;
    display: none;
}

.step_view_wrapper .circle {
    width: 30px;
    height: 30px;
    background: #000;
    border: 5px solid #06a5ed;
    border-radius: 100px;
    position: absolute;
    left: -10px;
    top: -10px;
}

.step_view_wrapper .circle.inactive {
    background: #ffffff;
    border-color: #999;
}

.step_view_wrapper .circle.two {
    left: 50%;
    margin-left: -10px;
}

.step_view_wrapper .circle.three {
    left: 100%;
    margin-left: -10px;
}

.step_view_wrapper .circle_labels {
    font-size: 14px;
    text-align: center;
    color: #fff;
    position: absolute;
    width: 120px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    top: 40px;
    left: -60px;
}

.radio_wrapper1 {
    width: 300px;
    height: 100px;
    background: #fcfcfc;
    display: flex;
    padding: 20px 0;
}

.radio_wrapper1 label {
    width: auto;
    height: 30px;

    display: block;
    margin: 5px;
}

.radio_wrapper1 label input[type="radio"] {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}

.radio_wrapper1 label span {
    width: auto;
    height: 20px;
    display: inline-block;
    margin-right: 30px;
    position: relative;
    top: -5px;
}

.radio_with_img_labels {
    width: 180px;
    height: 80px;
    background: #fff;
    color: #333;
    padding: 5px;
    margin: 10px 20px;
    display: inline-block;
}

.radio_with_img_labels img {
    display: block;
    margin: auto;
    height: 40px;
}

.radio_with_img_labels input[type="radio"] {
    width: 20px;
    height: 20px;
    display: block;
    margin: auto;
}

.special_panel {
    background: #fff;
    width: 100%;
}

.special_panel header {
    height: 40px;
    background: #333;
    color: #ccc;
    font-size: 16px;
    line-height: 40px;
    padding: 0 20px;
}

.special_panel .sp_body {
    background: #fff;
    padding: 20px;
}

.special_panel .sp_body .a_part {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 100%;
}

.special_panel .sp_body .a_part .infos {
    padding: 20px 0;
    color: #333;
    font-size: 16px;
}

.special_panel .sp_body .a_part .view_rate_card {
    display: block;
    background: #06a5ed;
    color: #fff;
    width: 160px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    right: 20px;
    bottom: -125px;
}

.label_and_element_wrapper.sp {
    width: 70%;
}

.lone_chk_label {
    padding: 20px;
    display: inline-block;
}

.lone_chk_label span {
    display: inline-block;
    position: relative;
    top: -5px;
}

.lone_chk_label input {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.address_details_wrapper {
    width: 45%;
    height: auto;
    display: inline-block;
    margin: 0 2%;
}

.adjust_width_full_wrapper {
    width: 94%;
    height: auto;
    display: inline-block;
    margin: 0 2%;
}

.address_details_wrapper header {
    height: 60px;
}

.address_details_wrapper header h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: block;
    float: left;
}

.address_details_wrapper header .yellow_bt {
    font-size: 20px;
    padding: 0 10px;
    float: right;
    width: 200px;
    height: 40px;
    background: #004a7c;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    display: block;
}

.address_details_wrapper header .yellow_bt i {
    font-size: 24px;
}

.service_logo {
    width: 300px;
    height: auto;
    padding: 20px 0;
}

.service_logo img {
    display: block;
    width: 100%;
    height: auto;
}

.selected_count {
    width: 130px;
    height: 40px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 40px;
    color: rgb(8, 82, 243);
    float: right;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    float: right;
    margin-right: 20px;
}

.selected_count .material-icons {
    font-size: 40px;
    top: -1px;
    left: -10px;
    position: relative;
    margin-right: 0;
}

.selected_count span {
    display: inline-block;
    position: relative;
    top: -13px;
    left: -8px;
}
</style>
<style scoped>
.service_details_container {
    padding: 20px;
}

.service_details_container > section {
    padding: 20px 0 0 0;
}

.service_details_container > section h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: inline-block;
}

.title_image {
    display: block;
    margin: auto;
}

h4 {
    font-weight: 600;
    text-align: center;
    padding: 30px 0;
    font-size: 18px;
}

.step_view_wrapper {
    width: 100%;
    height: 140px;
    background: #181818;
    padding: 20px 80px;
    position: relative;
}

.step_view_wrapper .strip {
    width: calc(100% - 160px);
    height: 10px;
    background: #666;
    position: absolute;
    top: 50%;
    line-height: 0;
}

.step_view_wrapper .strip_half {
    height: 10px;
    width: 50%;
    background: #06a5ed;
    display: inline-block;
    display: none;
}

.step_view_wrapper .circle {
    width: 30px;
    height: 30px;
    background: #000;
    border: 5px solid #06a5ed;
    border-radius: 100px;
    position: absolute;
    left: -10px;
    top: -10px;
}

.step_view_wrapper .circle.inactive {
    background: #ffffff;
    border-color: #999;
}

.step_view_wrapper .circle.two {
    left: 50%;
    margin-left: -10px;
}

.step_view_wrapper .circle.three {
    left: 100%;
    margin-left: -10px;
}

.step_view_wrapper .circle_labels {
    font-size: 14px;
    text-align: center;
    color: #fff;
    position: absolute;
    width: 120px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    top: 40px;
    left: -60px;
}

.label_and_element_wrapper.sp {
    width: 33%;
}

.lone_chk_label {
    padding: 20px;
    display: block;
}

.lone_chk_label input {
    display: inline-block;
    margin-right: 10px;
}

.service_logo {
    width: 300px;
    height: auto;
    padding: 20px 0;
}

.service_logo img {
    display: block;
    width: 100%;
    height: auto;
}

td.series_td {
    width: 220px;
}

table.tablce_cell_adj tr td {
    vertical-align: top;
    padding: 0;
}

table.tablce_cell_adj tr td input[type="checkbox"] {
    width: 20px;
    height: 20px;
    display: inline-block;
}

table.tablce_cell_adj tr td:nth-child(-n + 2) {
    width: auto;
}

table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(-n + 10) {
    width: 100px;
}

table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(n + 11) {
    width: 60px;
}

.new-error {
    border: 1px solid #dc0a0a !important;
}

.new_yellow_edit_bt {
    margin-left: 10px;
    padding: 0 10px;
    float: left;
    width: 200px;
    height: 40px;
    background: #004a7c;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    display: block;
}

.user_type {
    padding: 10px;
}

._line_item {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: block;
    float: left;
}

._sur_overflow {
    overflow-x: hidden;
}

@media screen and (max-width: 1520px) {
    ._sur_overflow {
        overflow-x: scroll;
    }
}

.chk_standard {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 6px;
}

._sur_container {
    display: block;
    width: 100%;
    background: #eee;
}

._sur_flex_container {
    display: flex;
    padding: 20px 0;
    width: 100%;
    min-width: 1150px;
    justify-content: space-between;
}

._sur_flex_boxes {
    width: auto;
    min-width: 40px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    background: #eee;
    padding: 0;
    margin-right: 5px;
}

._sur_flex_boxes .label_and_element_wrapper {
    padding: 2px;
}

._sur_flex_boxes input[type="text"],
._sur_flex_boxes label,
._sur_flex_boxes select {
    min-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (min-width: 1700px) {
    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes {
        width: 100%;
    }

    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes.small {
        max-width: 100px;
    }

    ._sur_container ._sur_flex_container:nth-child(2) ._sur_flex_boxes {
        width: 100%;
    }

    ._sur_flex_boxes.adj_1920 {
        max-width: 40px;
    }
}

@media screen and (min-width: 1900px) {
    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes {
        width: 100%;
    }

    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes.small {
        max-width: 100px;
    }

    ._sur_container ._sur_flex_container:nth-child(2) ._sur_flex_boxes {
        width: calc(100% - 5px);
    }

    ._sur_flex_boxes.adj_1920 {
        max-width: 40px;
    }
}
</style>

<style>
/*becaue this is for this date component*/
._sur_flex_boxes .label_and_element_wrapper.width_adj input[type="text"] {
    max-width: 120px;
}

@media screen and (min-width: 1700px) {
    ._sur_flex_boxes .label_and_element_wrapper.width_adj input[type="text"] {
        max-width: calc(100% - 10px);
    }
}

.pickup-address-label {
    white-space: nowrap;
    margin-left: 15px;
    background-color: white;
    width: calc(100vw - 427px);
    height: auto;
    padding: 10px;
    display: block;
    border: 1px solid #ccc;
}

.delivery-address-label {
    background-color: rgb(0, 74, 124);
    padding: 10px 12px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
}
.special_panel_2 .courier_row {
    border-bottom: 1px solid #c0c0c0;
}

.warning-border {
    border-color: red !important;
}

.find-couriers-preloader {
    background: #06A5ED none repeat scroll 0% 0%;
    padding: 8px 10px;
    font-weight: bold;
    position: absolute;
    right: -102px;
    bottom: 2px;
}
</style>
